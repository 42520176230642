import { alpha, styled } from '@material-ui/core'
import ApexChart from 'react-apexcharts'


export const Chart = styled(ApexChart)(
    ({ theme }) => (
        {
            '& .apexcharts-xaxistooltip': {
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[16],
                borderRadius: theme.shape.borderRadius,
                border: 0,
                '&::before, &::after': {
                    display: 'none',
                },
            },
            '& .apexcharts-legend': {
                '& .apexcharts-legend-group': {
                    flexDirection: 'row',
                    gap: theme.spacing(2),
                },
            },
            '& .apexcharts-tooltip': {
                '&.apexcharts-theme-light, &.apexcharts-theme-dark': {
                    backdropFilter: 'blur(6px)',
                    background: 'transparent',
                    border: 0,
                    boxShadow: 'none',
                    '& .apexcharts-tooltip-title': {
                        background: alpha('#838383', 0.9),
                        border: 0,
                        color: theme.palette.common.white,
                        margin: 0,
                    },
                    '& .apexcharts-tooltip-series-group': {
                        background: alpha('#838383', 0.8),
                        border: 0,
                        color: theme.palette.common.white,
                    },
                },
            },
        }
    ),
)

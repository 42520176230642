import { capitalizeText } from '@tivio/common'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { getMonetizationsAnalytics } from '../../../firebase/firestore/monetizationsAnalytics'
import { getMonetizationsCountsAnalytics } from '../../../firebase/firestore/monetizationsCountsAnalytics'
import Logger from '../../../logger'
import { CANCELLING_MONETIZATION_CHART_COLOR_PALETTE, MONETIZATION_CHART_COLOR_PALETTE } from '../../../static/constants'
import { MonetizationsPeriod } from '../../../types/date'
import { getChartCalendarWeekIntervals, getChartIntervals, getColorPaletteById } from '../../../utils/chart.utils'
import { useOrganization } from '../../hooks'

import {
    getCancellingMonetizationsAggregationMatrix,
    getChurnedChartSeries,
    getChurnedMonetizationsAggregationMatrix,
    getGroupedDataPPVIncome,
    getGroupedDataTotalIncome,
    getMonetizationAggregationMatrix,
    getMonetizationCountsChartSeries,
    getMonetizationsCountsAggregationMatrix,
    getMonetizationSummaryList,
    getSeriesData,
    getSeriesPPVData,
} from './services'

import type { MonetizationAggregationMatrix } from './services'
import type {
    CancellingMonetizationDetail,
    IncomeDetail,
    MonetizationIncomeDetail,
    MonetizationSummaryDetail,
    PayPerViewIncomeDetail,
} from './types'
import type { ChartIntervals, ChartSeries } from '../../../types/chart'
import type { CancellingMonetizationsSummaryItem, ChurnedMonetizationsSummaryItem, MonetizationsCountsSummaryItem, MonetizationSummaryItem } from '@tivio/types'


interface ProcessMethodOptions {
    monetizationAggregationMatrix: MonetizationAggregationMatrix
    periodChartIntervals: ChartIntervals
    colorPalette?: { [key: string]: string }
}

interface ProcessIncomeOptions {
    monetizationsAnalytics: MonetizationSummaryItem[]
    startDate: string
    endDate: string
    monetizationsPeriod: MonetizationsPeriod
}

interface ProcessMonetizationsCountsOptions {
    monetizationsCountsAnalytics: MonetizationsCountsSummaryItem[]
    startDate: string
    endDate: string
}

interface ProcessCancellingMonetizationsOptions {
    cancellingMonetizationsAnalytics: CancellingMonetizationsSummaryItem[]
    startDate: string
    endDate: string
}

interface ProcessChurnedMonetizationsOptions {
    churnedMonetizationsAnalytics: ChurnedMonetizationsSummaryItem[]
    startDate: string
    endDate: string
}

interface UseMonetizationsDashboard {
    isLoading: boolean
    dashboardData: {
        generalTotalIncome: IncomeDetail
        subscriptionTotalIncome: IncomeDetail
        payPerViewTotalIncome: IncomeDetail
        totalMonetizationSummaryList: MonetizationIncomeDetail[]
        subscriptionSummaryList: MonetizationIncomeDetail[]
        ppvSummaryList: PayPerViewIncomeDetail[]
        totalChartSeries: ChartSeries[]
        subscriptionChartSeries: ChartSeries[]
        cancellingMonetizationChartSeries: ChartSeries[]
        churnedMonetizationChartSeries: ChartSeries[]
        ppvChartSeries: ChartSeries[]
    }
}

const DEFAULT_INCOME_VALUE: IncomeDetail = { incomeValue: 0 }

const logger = new Logger('UseMonetizationsDashboard')

interface Props {
    startDate: string
    endDate: string
    startDateMonetizationCounts: string
    endDateMonetizationCounts: string
    monetizationsPeriod: MonetizationsPeriod
    monetizationsCountsPeriod: MonetizationsPeriod
}

export const useMonetizationsDashboard = ({ 
    startDate,
    endDate,
    startDateMonetizationCounts,
    endDateMonetizationCounts,
    monetizationsPeriod,
    monetizationsCountsPeriod,
}: Props): UseMonetizationsDashboard => {
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
    const [isLoadingMonetizationsCounts, setIsLoadingMonetizationsCounts] = useState<boolean>(true)

    const [generalTotalIncome, setGeneralTotalIncome] = useState<IncomeDetail>(DEFAULT_INCOME_VALUE)
    const [subscriptionTotalIncome, setSubscriptionTotalIncome] = useState<IncomeDetail>(DEFAULT_INCOME_VALUE)
    const [payPerViewTotalIncome, setPayPerViewTotalIncome] = useState<IncomeDetail>(DEFAULT_INCOME_VALUE)

    const [totalMonetizationSummaryList, setTotalMonetizationSummaryList] = useState<MonetizationIncomeDetail[]>([])
    const [totalChartSeries, setTotalChartSeries] = useState<ChartSeries[]>([])

    const [subscriptionSummaryList, setSubscriptionSummaryList] = useState<MonetizationIncomeDetail[]>([])
    const [subscriptionChartSeries, setSubscriptionChartSeries] = useState<ChartSeries[]>([])

    const [cancellingMonetizationChartSeries, setCancellingMonetizationChartSeries] = useState<ChartSeries[]>([])
    const [churnedMonetizationChartSeries, setChurnedMonetizationChartSeries] = useState<ChartSeries[]>([])

    const [ppvSummaryList, setPPVSummaryList] = useState<PayPerViewIncomeDetail[]>([])
    const [ppvChartSeries, setPPVChartSeries] = useState<ChartSeries[]>([])

    const [t] = useTranslation()
    const { organization } = useOrganization()

    const processTotalIncomes = useCallback(async (options: ProcessMethodOptions) => {
        const { monetizationAggregationMatrix, periodChartIntervals, colorPalette } = options
        const totalMonetizationSummaries = getGroupedDataTotalIncome(monetizationAggregationMatrix.monetizationSummaryDetails)
        const totalSeriesData = getSeriesData({
            dataDetails: totalMonetizationSummaries,
            chartIntervals: periodChartIntervals,
            colorPalette,
        })

        const totalMonetizationSummaryList = getMonetizationSummaryList({
            monetizationSummaryDetails: totalMonetizationSummaries,
            generalTotalIncome: monetizationAggregationMatrix.generalTotalIncome,
        })

        setTotalMonetizationSummaryList(totalMonetizationSummaryList)
        setTotalChartSeries(totalSeriesData)
    }, [])

    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const processSubscriptionIncomes = useCallback(async (options: ProcessMethodOptions) => {
        const { monetizationAggregationMatrix, periodChartIntervals } = options
        const subscriptionMonetizationSummaries = monetizationAggregationMatrix
            .monetizationSummaryDetails.filter(({ monetizationType }) => monetizationType === 'subscription')

        const subscriptionSummaryList = getMonetizationSummaryList({
            monetizationSummaryDetails: subscriptionMonetizationSummaries,
            generalTotalIncome: monetizationAggregationMatrix.subscriptionTotalIncome,
        })
        const getColorKey = (index: number, dataDetail: MonetizationSummaryDetail) =>
            dataDetail.monetizationId && dataDetail.purchasedPrice ? `${dataDetail.monetizationId}-${dataDetail.purchasedPrice}` : index.toString()
        const getSeriesName = (monetizationSummary: MonetizationSummaryDetail) => {
            const monetizationFrequencyString = t(capitalizeText(monetizationSummary.monetizationFrequency.replace('_', ' ')))
            const suffix = `${monetizationSummary.purchasedPrice}${organization?.currencySymbol}/ ${monetizationFrequencyString}`
            return `${monetizationSummary.title} ${suffix}`
        }
        const subscriptionSeriesData = getSeriesData({
            dataDetails: subscriptionMonetizationSummaries,
            chartIntervals: periodChartIntervals,
            colorPalette: getColorPaletteById({ data: subscriptionMonetizationSummaries, getKey: getColorKey }),
            getSeriesName,
            getColorKey,
        })

        setSubscriptionSummaryList(subscriptionSummaryList)
        setSubscriptionChartSeries(subscriptionSeriesData)
    }, [organization?.currencySymbol, t])

    const processPPVIncomes = useCallback(async (options: ProcessMethodOptions) => {
        const { monetizationAggregationMatrix, periodChartIntervals, colorPalette } = options
        const ppvMonetizationSummaries = monetizationAggregationMatrix
            .monetizationSummaryDetails.filter(({ monetizationType }) => monetizationType === 'transaction')

        const ppvSummaryDetailList = await getGroupedDataPPVIncome(ppvMonetizationSummaries)

        const ppvSeriesData = getSeriesPPVData({
            dataDetails: ppvSummaryDetailList,
            chartIntervals: periodChartIntervals,
            colorPalette,
        })

        setPPVSummaryList(ppvSummaryDetailList)
        setPPVChartSeries(ppvSeriesData)
    }, [])

    const processIncomes = useCallback(async ({ monetizationsAnalytics, startDate, endDate, monetizationsPeriod }: ProcessIncomeOptions) => {
        const purchasableMonetizations = [...(organization?.subscriptions ?? []), ...(organization?.transactions ?? [])]

        const currentPeriodChartIntervals = monetizationsPeriod === MonetizationsPeriod.LAST_SEVEN_DAYS ?
            getChartIntervals(endDate, 7, dayjs(endDate).diff(startDate, 'days')) :
            getChartCalendarWeekIntervals(endDate, dayjs(endDate).diff(startDate, 'weeks'))

        const totalColorPalette = {
            ...getColorPaletteById({
                data: (organization?.subscriptions ?? []).sort((a, b) => b.getId.localeCompare(a.getId)),
                getKey: (index, dataDetail) => dataDetail.getId ?? index.toString(),
            }),
            ppv: MONETIZATION_CHART_COLOR_PALETTE[MONETIZATION_CHART_COLOR_PALETTE.length - 1],
        }
        const transactionColorPalette = getColorPaletteById({
            data: (organization?.transactions ?? []).sort((a, b) => b.getId.localeCompare(a.getId)),
            getKey: (index, dataDetail) => dataDetail.getId ?? index.toString(),
        })

        const currentAggregationMatrix = getMonetizationAggregationMatrix({
            monetizationsAnalytics,
            monetizations: purchasableMonetizations,
            chartIntervals: currentPeriodChartIntervals,
        })

        const baseProcessDataOptions = {
            monetizationAggregationMatrix: currentAggregationMatrix,
            periodChartIntervals: currentPeriodChartIntervals,
        }

        processTotalIncomes({ ...baseProcessDataOptions, colorPalette: totalColorPalette })
        // processSubscriptionIncomes(baseProcessDataOptions)
        processPPVIncomes({ ...baseProcessDataOptions, colorPalette: transactionColorPalette })

        setGeneralTotalIncome({ incomeValue: currentAggregationMatrix.generalTotalIncome })
        setSubscriptionTotalIncome({ incomeValue: currentAggregationMatrix.subscriptionTotalIncome })
        setPayPerViewTotalIncome({ incomeValue: currentAggregationMatrix.payPerViewTotalIncome })

    }, [organization?.subscriptions, organization?.transactions, processPPVIncomes, processTotalIncomes])

    const processMonetizationsCounts = useCallback(async ({ monetizationsCountsAnalytics, startDate, endDate }: ProcessMonetizationsCountsOptions) => {
        const purchasableMonetizations = organization?.subscriptions ?? []

        const currentPeriodChartIntervals = getChartIntervals(endDate, dayjs(endDate).diff(startDate, 'days'), dayjs(endDate).diff(startDate, 'days'))

        const monetizationsCountsAggregationMatrix = getMonetizationsCountsAggregationMatrix({
            chartIntervals: currentPeriodChartIntervals,
            monetizations: purchasableMonetizations,
            monetizationsCountsAnalytics,
        })

        const subscriptionSeriesData = getMonetizationCountsChartSeries(monetizationsCountsAggregationMatrix, currentPeriodChartIntervals)

        setSubscriptionChartSeries(subscriptionSeriesData)
    }, [organization?.subscriptions])


    const processCancellingMonetizations = useCallback(async ({ 
        cancellingMonetizationsAnalytics,
        startDate,
        endDate,
    }: ProcessCancellingMonetizationsOptions) => {
        const purchasableMonetizations = organization?.subscriptions ?? []

        const currentPeriodChartIntervals = getChartIntervals(endDate, dayjs(endDate).diff(startDate, 'days'), dayjs(endDate).diff(startDate, 'days'))

        const cancellingMonetizationsAggregationMatrix = getCancellingMonetizationsAggregationMatrix({
            chartIntervals: currentPeriodChartIntervals,
            monetizations: purchasableMonetizations,
            cancellingMonetizationsAnalytics,
        })

        const getColorKey = (index: number, dataDetail: CancellingMonetizationDetail) =>
            dataDetail.monetizationId && dataDetail.monetizationId ? dataDetail.monetizationId : index.toString()
        const getSeriesName = (monetizationSummary: CancellingMonetizationDetail) => {
            const monetizationFrequencyString = t(capitalizeText(monetizationSummary.monetizationFrequency.replace('_', ' ')))
            const suffix = `${monetizationSummary.title} / ${monetizationFrequencyString}`
            return `${monetizationSummary.title} ${suffix}`
        }


        const cancellingMonetizationSeriesData = getSeriesData<CancellingMonetizationDetail>({
            dataDetails: cancellingMonetizationsAggregationMatrix,
            chartIntervals: currentPeriodChartIntervals,
            colorPalette: getColorPaletteById<CancellingMonetizationDetail>({
                data: cancellingMonetizationsAggregationMatrix,
                getKey: getColorKey,
                colorPalette: CANCELLING_MONETIZATION_CHART_COLOR_PALETTE,
            }),
            getSeriesName,
            getColorKey,
        })

        setCancellingMonetizationChartSeries(cancellingMonetizationSeriesData)
    }, [organization?.subscriptions])


    const processChurnedMonetizations = useCallback(async ({ churnedMonetizationsAnalytics, startDate, endDate }: ProcessChurnedMonetizationsOptions) => {
        const purchasableMonetizations = organization?.subscriptions ?? []

        const currentPeriodChartIntervals = getChartIntervals(endDate, dayjs(endDate).diff(startDate, 'days'), dayjs(endDate).diff(startDate, 'days'))
        const churnedMonetizationsAggregationMatrix = getChurnedMonetizationsAggregationMatrix({
            chartIntervals: currentPeriodChartIntervals,
            monetizations: purchasableMonetizations,
            churnedMonetizationsAnalytics,
        })
        
        const newSeries = getChurnedChartSeries(
            churnedMonetizationsAggregationMatrix,
            currentPeriodChartIntervals,
        )

        setChurnedMonetizationChartSeries(newSeries)
    }, [organization?.subscriptions])

    const getData = useCallback(async (organizationId: string, startDate: string, endDate: string, monetizationsPeriod: MonetizationsPeriod) => {
        try {
            setIsLoadingData(true)
            const monetizationsAnalyticData = await getMonetizationsAnalytics({ organizationId, startDate, endDate })
            processIncomes({ monetizationsAnalytics: monetizationsAnalyticData.monetizationsAnalytics, startDate, endDate, monetizationsPeriod })

            const cancelling: CancellingMonetizationsSummaryItem[] = monetizationsAnalyticData.monetizationsAnalytics.map((item) => {
                return {
                    date: item.date,
                    monetizationId: item.monetizationId,
                    cancellingUsers: item.cancellingPaymentCount,
                }
            })

            const churned: ChurnedMonetizationsSummaryItem[] = monetizationsAnalyticData.monetizationsAnalytics.map((item) => {
                return {
                    date: item.date,
                    monetizationId: item.monetizationId,
                    canceledUsers: item.cancelledPaymentCount2,
                    expiredUsers: item.expiredPaymentCount,
                }   
            })

            await Promise.all([
                processCancellingMonetizations({ 
                    cancellingMonetizationsAnalytics: cancelling,
                    startDate: startDateMonetizationCounts,
                    endDate: endDateMonetizationCounts,
                }),
                processChurnedMonetizations({
                    churnedMonetizationsAnalytics: churned,
                    startDate: startDateMonetizationCounts,
                    endDate: endDateMonetizationCounts,
                }),
            ])
            logger.info('Monetization dashboard loaded')
        } catch (error) {
            logger.error(`Data load and processing failed with error: ${error}`)
        } finally {
            setIsLoadingData(false)
        }
    }, [processIncomes, processCancellingMonetizations, startDateMonetizationCounts, endDateMonetizationCounts, processChurnedMonetizations])

    const getMonetizationsCountsData = useCallback(async (organizationId: string, startDate: string, endDate: string) => {
        if (Math.abs(dayjs(endDate).diff(startDate, 'days')) > 91) {
            return
        }
        try {
            setIsLoadingMonetizationsCounts(true)
            const monetizationsCountsAnalyticData = await getMonetizationsCountsAnalytics({ organizationId, startDate, endDate })
            processMonetizationsCounts({ monetizationsCountsAnalytics: monetizationsCountsAnalyticData.monetizationsCountsAnalytics, startDate, endDate })
            logger.info('Monetization dashboard loaded')
        } catch (error) {
            logger.error(`Data load and processing failed with error: ${error}`)
        } finally {
            setIsLoadingMonetizationsCounts(false)
        }
    }, [processMonetizationsCounts])

    useEffect(() => {
        if (!organization?.id) {
            return
        }
        getData(organization.id, startDate, endDate, monetizationsPeriod)
        // TODO organization.id or getData method create infinity loop resolve in future
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, monetizationsPeriod, startDateMonetizationCounts, endDateMonetizationCounts])

    useEffect(() => {
        if (!organization?.id) {
            return
        }
        getMonetizationsCountsData(organization.id, startDateMonetizationCounts, endDateMonetizationCounts)
        // TODO organization.id or getData method create infinity loop resolve in future
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDateMonetizationCounts, endDateMonetizationCounts, monetizationsCountsPeriod])

    return {
        isLoading: isLoadingData || isLoadingMonetizationsCounts,
        dashboardData: {
            totalChartSeries,
            subscriptionChartSeries,
            ppvChartSeries,
            ppvSummaryList,
            subscriptionSummaryList,
            totalMonetizationSummaryList,
            generalTotalIncome,
            subscriptionTotalIncome,
            payPerViewTotalIncome,
            cancellingMonetizationChartSeries,
            churnedMonetizationChartSeries,
        },
    }
}

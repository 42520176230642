import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { MonetizationsPeriod } from '../../../types/date'
import { useOrganization } from '../../hooks'
import { MonetizationsPeriodPicker } from '../../periodPickers/MonetizationsPeriodPicker'
import { Row } from '../../uiLayout/Row'
import { CenteredLoader } from '../CenteredLoader'
import { OverviewCard } from '../OverviewCard'

import { MonetizationsChartCard } from './MonetizationsChartCard'
import { PayPerViewSummaryList } from './PayPerViewSummaryList'
import { TotalMonetizationSummaryList } from './TotalMonetizationSummaryList'
import { useMonetizationsDashboard } from './useMonetizationsDashboard'

import type { ChartSeries } from '../../../types/chart'
import type { DateRange } from '../../../types/date'


dayjs.extend(utc)

enum Detail {
    TOTAL_INCOME = 'totalIncome',
    SUBSCRIPTION_INCOME = 'subscriptionIncome',
    PAY_PER_VIEW_INCOME = 'payPerViewIncome',
}

const DEFAULT_DATE_RANGE: DateRange = {
    startDate: dayjs().utc().subtract(30, 'day').startOf('week').toISOString(),
    endDate: dayjs().utc().endOf('day').toISOString(),
}

const DEFAULT_DATE_RANGE_MONETIZATIONS_COUNTS: DateRange = {
    startDate: dayjs().utc().subtract(7, 'day').toISOString(),
    endDate: dayjs().utc().endOf('day').toISOString(),
}

export const MonetizationsDashboard: React.FC = () => {
    const [startDate, setStartDate] = useState<string>(DEFAULT_DATE_RANGE.startDate)
    const [endDate, setEndDate] = useState<string>(DEFAULT_DATE_RANGE.endDate)
    const [startDateMonetizationCounts, setStartDateMonetizationCounts] = useState<string>(DEFAULT_DATE_RANGE_MONETIZATIONS_COUNTS.startDate)
    const [endDateMonetizationCounts, setEndDateMonetizationCounts] = useState<string>(DEFAULT_DATE_RANGE_MONETIZATIONS_COUNTS.endDate)
    const [monetizationsPeriod, setMonetizationsPeriod] = useState<MonetizationsPeriod>(MonetizationsPeriod.LAST_FOUR_WEEKS)
    const [monetizationsCountsPeriod, setMonetizationsCountsPeriod] = useState<MonetizationsPeriod>(MonetizationsPeriod.LAST_SEVEN_DAYS)
    const [currentDetail, setCurrentDetail] = useState<Detail>(Detail.TOTAL_INCOME)

    const { organization } = useOrganization()
    const { isLoading, dashboardData } = useMonetizationsDashboard({
        startDate,
        endDate,
        startDateMonetizationCounts,
        endDateMonetizationCounts,
        monetizationsPeriod,
        monetizationsCountsPeriod,
    })
    const [t] = useTranslation()
    const isSubscriptionIncome = useMemo(() => currentDetail === Detail.SUBSCRIPTION_INCOME, [currentDetail])

    const handlePeriodChange = (startDate: Date, endDate: Date, monetizationsPeriod: MonetizationsPeriod) => {
        if (isSubscriptionIncome) {
            setStartDateMonetizationCounts(dayjs(startDate).utc(true).toISOString())
            setEndDateMonetizationCounts(dayjs(endDate).utc(true).toISOString())
            setMonetizationsCountsPeriod(monetizationsPeriod)
            setMonetizationsPeriod(monetizationsPeriod)
            return
        }
        setStartDate(dayjs(startDate).utc(true).toISOString())
        setEndDate(dayjs(endDate).utc(true).toISOString())
        setMonetizationsPeriod(monetizationsPeriod)
    }

    const seriesData = (() => {
        switch (currentDetail) {
            case Detail.SUBSCRIPTION_INCOME:
                return dashboardData.subscriptionChartSeries
            case Detail.PAY_PER_VIEW_INCOME:
                return dashboardData.ppvChartSeries
            case Detail.TOTAL_INCOME:
            default:
                return dashboardData.totalChartSeries
        }
    })()

    const getSelectColor = (selectedDetail: Detail) => currentDetail === selectedDetail ? 'white' : undefined

    const getListComponent = () => {
        switch (currentDetail) {
            case Detail.SUBSCRIPTION_INCOME:
                // return (<SubscriptionSummaryList subscriptionSummaryList={dashboardData.subscriptionSummaryList} />)
                return null
            case Detail.PAY_PER_VIEW_INCOME:
                return (<PayPerViewSummaryList payPerViewSummaryList={dashboardData.ppvSummaryList} />)
            case Detail.TOTAL_INCOME:
            default:
                return (<TotalMonetizationSummaryList monetizationIncomeDetails={dashboardData.totalMonetizationSummaryList} />)
        }

    }

    // TODO in future there are possibility that It will be enumerated base on MonetizationPeriod or Date range picker
    const getTickAmount = (chartSeries: ChartSeries[]) => {
        const tickAmount = chartSeries.reduce((tickAmount, currChartSeries) => {
            const newTickAmount = currChartSeries.data.length - 1
            return tickAmount > newTickAmount ? tickAmount : newTickAmount
        }, 0)
        return tickAmount
    }

    const xAxisFormatter = (value: number | string) => {
        const formatString = isSubscriptionIncome || monetizationsPeriod === MonetizationsPeriod.LAST_SEVEN_DAYS ? 'DD MMM' : 'w'
        return dayjs(value).format(formatString)
    }

    const tooltipFormatter = (value: number | string) => {
        if (isSubscriptionIncome || monetizationsPeriod === MonetizationsPeriod.LAST_SEVEN_DAYS) {
            return dayjs(value).format('DD.MM. (ddd)')
        }

        const weekNumber = dayjs(value).format('w')
        const year = dayjs(value).format('yy')
        return weekNumber === '1' ? `${weekNumber}w (${year})` : `${weekNumber}w`
    }

    return (
        <>
            <Row
                spacing={2}
                rowProps={{ justifyContent: 'flex-end' }}
                itemProps={{ xs: 12 }}
            >
                <MonetizationsPeriodPicker
                    isSubscriptionsCount={isSubscriptionIncome}
                    monetizationsPeriod={isSubscriptionIncome ? monetizationsCountsPeriod : monetizationsPeriod}
                    setMonetizationsPeriod={isSubscriptionIncome ? setMonetizationsCountsPeriod : setMonetizationsPeriod}
                    onChange={handlePeriodChange}
                />
            </Row>
            {isLoading ?
                (
                    <CenteredLoader />
                ) :
                (
                    <>
                        <Row
                            spacing={2}
                            rowProps={{ alignItems: 'stretch', justifyContent: 'flex-start' }}
                            itemProps={{ xs: 3 }}
                        >
                            <OverviewCard
                                title={t('Total income')}
                                value={dashboardData.generalTotalIncome.incomeValue}
                                valueSuffix={organization?.currencySymbol}
                                onClick={() => {
                                    setCurrentDetail(Detail.TOTAL_INCOME)
                                }}
                                selectColor={getSelectColor(Detail.TOTAL_INCOME)}
                                key="total-income"
                            />
                            <OverviewCard
                                title={t('Subscriptions')}
                                value={dashboardData.subscriptionTotalIncome.incomeValue}
                                valueSuffix={organization?.currencySymbol}
                                onClick={() => {
                                    setCurrentDetail(Detail.SUBSCRIPTION_INCOME)
                                }}
                                selectColor={getSelectColor(Detail.SUBSCRIPTION_INCOME)}
                                key="subscriptions"
                            />
                            <OverviewCard
                                title={t('PPV')}
                                value={dashboardData.payPerViewTotalIncome.incomeValue}
                                valueSuffix={organization?.currencySymbol}
                                onClick={() => {
                                    setCurrentDetail(Detail.PAY_PER_VIEW_INCOME)
                                }}
                                selectColor={getSelectColor(Detail.PAY_PER_VIEW_INCOME)}
                                key="ppv"
                            />
                        </Row>
                        <Row
                            spacing={2}
                            rowProps={{ alignItems: 'stretch', justifyContent: 'flex-start' }}
                            itemProps={{ xs: 12 }}
                        >
                            <MonetizationsChartCard
                                series={seriesData}
                                tickAmount={getTickAmount(seriesData)}
                                currency={isSubscriptionIncome ? undefined : organization?.currencySymbol ?? 'Kč'}
                                xAxisFormatter={xAxisFormatter}
                                tooltipFormatter={tooltipFormatter}
                                title={isSubscriptionIncome ? t('Total subscriptions') : undefined}
                            />
                            {isSubscriptionIncome && <MonetizationsChartCard
                                series={dashboardData.cancellingMonetizationChartSeries}
                                tickAmount={getTickAmount(dashboardData.cancellingMonetizationChartSeries)}
                                xAxisFormatter={xAxisFormatter}
                                tooltipFormatter={tooltipFormatter}
                                title={t('Cancelling subscriptions')}
                            />}
                            {isSubscriptionIncome && <MonetizationsChartCard
                                series={dashboardData.churnedMonetizationChartSeries}
                                tickAmount={getTickAmount(dashboardData.churnedMonetizationChartSeries)}
                                xAxisFormatter={xAxisFormatter}
                                tooltipFormatter={tooltipFormatter}
                                title={t('Churned subscriptions')}
                            />}
                        </Row>
                        <Row
                            spacing={2}
                            rowProps={{ alignItems: 'stretch', justifyContent: 'space-between' }}
                            itemProps={{ xs: 12 }}
                        >
                            {getListComponent()}
                        </Row>
                    </>
                )
            }
        </>
    )
}

import { getFunctions, loggerFirestore } from '../app'

import type { GetMonetizationsCountsAnalyticsResponse } from '@tivio/types'



interface GetConversionOptions {
    organizationId: string
    startDate: string
    endDate: string
}
export const getMonetizationsCountsAnalytics = async ({ organizationId, startDate, endDate }: GetConversionOptions) => {
    try {
        const getMonetizationsCountsAnalytics = getFunctions().httpsCallable('getMonetizationsCountsAnalytics')
        const response: { data: GetMonetizationsCountsAnalyticsResponse } = await getMonetizationsCountsAnalytics({
            organizationId,
            startDate,
            endDate,
        })

        loggerFirestore.info('Successfully fetched monetizations counts analytics.')
        return {
            monetizationsCountsAnalytics: response.data.monetizationsCountsAnalytics.map((item) => {
                return {
                    ...item,
                    date: Math.floor(new Date((item as any as { date: { value: string } }).date.value).getTime() / 1000),
                }
            }),
        }
    } catch (e) {
        loggerFirestore.error('Failed to create invite: ', e)
        throw new Error(e)
    }
}

import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import { Skeleton } from '@material-ui/lab'
import { type ALGOLIA_INDEX_NAME, VideoType } from '@tivio/types'
import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { getStorage } from '../../firebase/app'
import { isItemVideo } from '../../utils/video.utils'
import { useIndexedSearch } from '../hooks/dataHooks/useIndexedSearch'
import { useAlert } from '../hooks/uiHooks/useAlert'
import useBottomScrollListener from '../hooks/uiHooks/useBottomScrollListener'

import type { Tag } from '../../store/Tag'
import type Video from '../../store/Video'
import type { SearchFilterType } from '../hooks/dataHooks/useIndexedSearch'
import type { TvChannelDocument } from '@tivio/firebase'



interface ItemSearchItemProps {
    item: Video | Tag
    onClick: (itemPath: string) => void
    buttonText: string
    /**
     * If true, then 'Add' button is disabled
     */
    disabled?: boolean
    tvChannel: TvChannelDocument | null
    isProcessed: boolean
}

const useStyles = makeStyles((theme) => ({
    itemsList: {
        overflowX: 'hidden',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 300px)',
        padding: '4px', // Because of Grid margin overflow
    },
    itemContainer: {
        paddingRight: theme.spacing(1),
        '&:hover $itemButton': {
            opacity: 1,
        },
    },
    itemName: {
        fontWeight: 'bold',
    },
    itemDescription: {
        opacity: 0.7,
    },
    itemImg: {
        width: '85px',
        borderRadius: 4,

    },
    itemImgPlaceholder: {
        backgroundColor: theme.palette.grey[300],
        height: '48px',
    },
    itemButton: {
        opacity: 0,
        transition: 'opacity 150ms',
    },
    skeletonRow: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}))

const ItemSearchItem: React.FC<ItemSearchItemProps> = ({ item, onClick, buttonText, disabled }: ItemSearchItemProps) => {
    const classes = useStyles()

    const handleClick = () => {
        onClick(item.getDocumentPath)
    }

    return (
        <Box mt={1}>
            <Grid
                container
                spacing={1}
                alignItems="center"
                className={classes.itemContainer}
            >
                <Grid item>
                    {item.getCover ? (
                        <img
                            src={item.getCover}
                            className={classes.itemImg}
                        />
                    ) : (
                        <div className={clsx(classes.itemImg, classes.itemImgPlaceholder)}></div>
                    )}
                </Grid>
                <Grid
                    item
                    xs
                >
                    <div className={classes.itemName}>{item.getName}</div>
                    <div className={classes.itemDescription}>{item.id}</div>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.itemButton}
                        onClick={handleClick}
                        disabled={disabled}
                    >
                        {buttonText}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}


export interface EpgItemSearchProps {
    autoFocus?: boolean
    onAdd: (itemPath: string) => void
    buttonText: string
    searchIndex: ALGOLIA_INDEX_NAME.VIDEOS | ALGOLIA_INDEX_NAME.TAGS
    /**
     * Which filter to use against algolia index
     * @default 'combined'
     */
    filterType?: SearchFilterType
    /**
     * If true, then add buttons are disabled
     */
    disabled?: boolean
    tvChannelId: string
    tvChannel: TvChannelDocument | null
}

export const EpgItemSearch = ({ autoFocus, onAdd, buttonText, searchIndex, filterType = 'combined', disabled, tvChannel }: EpgItemSearchProps) => {
    const [t] = useTranslation()
    const classes = useStyles()
    const [searchText, setSearchText] = useState('')
    const [items, setItems] = useState<{
        video: Video,
        processed: boolean
    }[]>([])
    const search = useIndexedSearch(searchIndex, { limit: 30 }, true, filterType)
    const { showError } = useAlert()
    const handleBottomScroll = () => {
        if (search.pagination?.hasNextPage && !search.isLoading) {
            search.pagination.fetchMore()
        }
    }
    const scrollRef = useBottomScrollListener<HTMLDivElement>(handleBottomScroll, { offset: 200, triggerOnNoScroll: true })

    const handleResetSearch = () => {
        handleSearchChange()
    }

    const handleSearchChange = (evt?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const searchText = evt?.target.value ?? ''
        setSearchText(searchText)
        search.search(searchText)
    }

    const handleAddItem = async (itemPath: string, video: Video, processed: boolean) => {
        if (!processed) {
            // If video is not processed, we need to check if the input file exists first
            const doesFileExist = await checkDoesFileExist(video)
            // If file doesn't exist, we can't process the video
            if (!doesFileExist) {
                showError('Video is too old, and it\'s input file has been removed. Please reupload the video')
                return
            }
            const profileRef = tvChannel?.profileRefs?.at(0)
            if (profileRef) {
                const isProcessing = await video.isCurrentlyProcessing(profileRef)
                if (!isProcessing) {
                    video.processWithProfile(profileRef)
                }
            }
        }
        onAdd(itemPath)
    }

    const checkDoesFileExist = async (video: Video) => {
        let inputUrl = video.data.inputFileUrl

        // if video is VIRTUAL_PROGRAM, we need to get it's originalVideo and check it's inputFileUrl
        if (video.data.type === VideoType.VIRTUAL_PROGRAM) {
            const originalVideo = await video.originalVideoRef?.get() 
            const originalVideData = originalVideo?.data()
            if (originalVideData?.inputFileUrl) {
                inputUrl = originalVideData.inputFileUrl
            }
            // in case that original video is TASTING, we need to get it's originalVideo and check it's inputFileUrl
            if (originalVideData?.type === VideoType.TASTING) {
                const newOriginalVideo = await originalVideData.originalVideoRef?.get() 
                const newOriginalVideData = newOriginalVideo?.data()
                if (newOriginalVideData?.inputFileUrl) {
                    inputUrl = newOriginalVideData.inputFileUrl
                }
            }
        }

        if (inputUrl) {
            try {
                const storageRef = getStorage().refFromURL(inputUrl)
                return !!(await storageRef.getDownloadURL().catch(() => false))
            } catch (e) {
                console.error('Error getting download URL', e)
                return false
            }
        }
        return false
    }

    const loadItems = useCallback(async () => {
        const videoItems = search.pagination?.items.filter(isItemVideo) as Video[]
        if (videoItems) {
            const data = await Promise.all(videoItems.map(async (video) => {
                const isVideoProcessed = tvChannel && await video.getProfile(tvChannel)
                return {
                    video,
                    processed: !!isVideoProcessed,
                }
            }))

            setItems(data)
        }
    }, [search.pagination?.items, tvChannel])

    useEffect(() => {
        loadItems()
    }, [loadItems, search.pagination?.items])

    return (
        <>
            <Box mb={2}>
                <TextField
                    autoFocus={autoFocus}
                    onChange={handleSearchChange}
                    value={searchText}
                    placeholder={t('Search video...')}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                        'startAdornment': (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        'endAdornment': searchText ? (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    title={t('Clear search')}
                                    onClick={handleResetSearch}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : undefined,
                    }}
                />

            </Box>
            <Box
                mb={2}
            >
                <div
                    ref={scrollRef}
                    className={classes.itemsList}
                >
                    {items.map(item => (
                        <ItemSearchItem
                            item={item.video}
                            key={item.video.id}
                            onClick={(path) => handleAddItem(path, item.video, item.processed)}
                            buttonText={t('Add')}
                            disabled={disabled}
                            tvChannel={tvChannel}
                            isProcessed={item.processed}
                        />
                    ))}
                    {search.isLoading && (
                        <>
                            {Array.from(Array(8), () => <Skeleton
                                key={Math.random()}
                                animation="wave"
                                className={classes.skeletonRow}
                            />)}
                        </>
                    )}
                    {search.isEmptyResult && (
                        <Box
                            mt={2}
                            textAlign="center"
                        >
                            <Typography variant="body1">{t('No videos found.')}</Typography>
                        </Box>
                    )}
                    {search.error && (
                        <Box
                            mt={2}
                            textAlign="center"
                        >
                            <Typography
                                variant="body1"
                                color="error"
                            >{search.error.message}</Typography>
                        </Box>
                    )}
                </div>
            </Box>
        </>
    )
}

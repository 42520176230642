import { Button, ButtonGroup, makeStyles } from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import ImageIcon from '@material-ui/icons/Image'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import TitleIcon from '@material-ui/icons/Title'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import type { ArticleBlockType } from '@tivio/types'


const useStyles = makeStyles((theme) => ({
    buttonGroup: {
        '& button': {
            padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
            textTransform: 'none',
        },
    },
}))

interface Props {
    onAddNewBlock: (blockType: ArticleBlockType) => void
}

export const BlockTypeButtonGroup = ({ onAddNewBlock }: Props) => {
    const classes = useStyles()
    const [t] = useTranslation()
    return (
        <ButtonGroup
            size="large"
            className={classes.buttonGroup}
        >
            <Button
                startIcon={<TitleIcon/>}
                onClick={() => onAddNewBlock('PARAGRAPH')}
            >
                {t('Text block')}
            </Button>
            <Button
                startIcon={<TitleIcon/>}
                onClick={() => onAddNewBlock('BLOCK_QUOTE')}
            >
                {t('Block quote')}
            </Button>
            <Button
                startIcon={<ImageIcon/>}
                onClick={() => onAddNewBlock('IMAGE')}
            >
                {t('Image')}
            </Button>
            <Button
                startIcon={<OndemandVideoIcon/>}
                onClick={() => onAddNewBlock('VIDEO')}
            >
                Video
            </Button>
            <Button
                startIcon={<AttachMoneyIcon/>}
                onClick={() => onAddNewBlock('PAYWALL')}
            >
                Paywall
            </Button>
        </ButtonGroup>
    )
}

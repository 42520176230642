import React, { useState } from 'react'
import { Chip, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next/hooks'
import { Header } from '../common'
import { useDialog } from '../hooks/uiHooks/useDialog'
import { ContentSurveyDialog } from './ContentSurveyDialog'
import { AppThemeProvider } from '../AppThemeProvider'
import { Row } from '../uiLayout/Row'
import { Article } from '../../store/Article'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { SurveyItem } from '@tivio/firebase'
import { makeStyles } from '@material-ui/core/styles'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'

const useStyles = makeStyles({
    draggingItem: {
        backgroundColor: 'lightblue',
    },
})

interface ContentSurveySettingsProps {
    content: Article
    disabled?: boolean
}

export const ContentSurvey: React.FC<ContentSurveySettingsProps> = ({ content, disabled = false }) => {
    const [t] = useTranslation()
    const [selectedSurveyItem, setSelectedSurveyItem] = useState<SurveyItem | undefined>(undefined)
    const { openDialog, isDialogOpen, closeDialog } = useDialog()
    const [surveyItems, setSurveyItems] = useState<SurveyItem[]>(content.surveyItems)
    const classes = useStyles()
    const { confirmAction } = useConfirmAction()

    const handleDelete = (item: SurveyItem) => {
        confirmAction(
            () => {
                const updatedItems = surveyItems.filter(surveyItem => surveyItem.id !== item.id)
                setSurveyItems(updatedItems)
                content.updateSurveyItems(updatedItems)
            },
            `${t('Are you sure you want to delete this survey item')} ${item.title}?`,
        )
    }

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return
        }

        const reorderedItems = Array.from(surveyItems)
        const [movedItem] = reorderedItems.splice(result.source.index, 1)
        reorderedItems.splice(result.destination.index, 0, movedItem)

        setSurveyItems(reorderedItems)
        content.updateSurveyItems(reorderedItems)
    }

    return (
        <>
            <ContentSurveyDialog
                isOpen={isDialogOpen}
                onClose={closeDialog}
                surveyItem={selectedSurveyItem}
                onSurveyItemChange={(updatedItems) => {
                    setSurveyItems(updatedItems)
                    content.updateSurveyItems(updatedItems)
                }}
                content={content}
            />
            <Header
                title={t('Survey Items')}
                actions={{
                    create: !disabled ? {
                        title: t('Add metadata'),
                        onCreate: () => {
                            setSelectedSurveyItem(undefined)
                            openDialog()
                        },
                    } : undefined,
                }}
            />

            {surveyItems.length === 0 ? (<Typography color="textSecondary">{t('No metadata')}</Typography>) :
                <AppThemeProvider type="light">
                    <DragDropContext
                        onDragEnd={handleDragEnd}
                    >
                        <Droppable droppableId="surveyItems" direction="horizontal">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    <Row spacing={1}>
                                        {
                                            surveyItems.map((item: SurveyItem, index: number) => (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <Chip
                                                            disabled={disabled}
                                                            key={item.id}
                                                            label={item.title}
                                                            onClick={() => {
                                                                setSelectedSurveyItem(item)
                                                                openDialog(item)
                                                            }}
                                                            onDelete={() => handleDelete(item)}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={snapshot.isDragging ? classes.draggingItem : ''}
                                                        />
                                                    )}
                                                </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                    </Row>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </AppThemeProvider>
            }
        </>
    )
}
import { VideoDocument } from '@tivio/firebase'

import Organization from '../store/Organization'
import Section from '../store/Section'
import Video from '../store/Video'

import type firebase from 'firebase/app'


const createGlobalVideo = (
    ref: firebase.firestore.DocumentReference<VideoDocument>,
    data: VideoDocument,
    organization: Organization,
): Video => {
    return new Video(
        ref,
        data,
        organization
    )
}

const createVideo = (
    ref: firebase.firestore.DocumentReference<VideoDocument>,
    data: VideoDocument,
    organization: Organization,
): Video => {
    return new Video(
        ref,
        data,
        organization,
    )
}

const createSectionVideo = (
    ref: firebase.firestore.DocumentReference<VideoDocument>,
    data: VideoDocument,
    section: Section,
    organization: Organization,
    playlist?: Video,
): Video => {
    return new Video(
        ref,
        data,
        organization,
        undefined,
        section,
        playlist,
    )
}

const createSectionPlaylist = (
    ref: firebase.firestore.DocumentReference<VideoDocument>,
    data: VideoDocument,
    section: Section,
    organization: Organization,
) => {
    return new Video(
        ref,
        data,
        organization,
        undefined,
        section,
    )
}

export {
    createGlobalVideo,
    createVideo,
    createSectionPlaylist,
    createSectionVideo,
}

import { useTheme } from '@material-ui/core'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import React from 'react'

import { Chart } from '../../externalComponents/Chart'
import { AnalyticCard } from '../AnalyticCard'

import type { ChartSeries } from '../../../types/chart'
import type { Theme } from '@material-ui/core'
import type { ApexOptions } from 'apexcharts'


dayjs.extend(weekOfYear)
dayjs.extend(advancedFormat)

interface GetChartOptions {
    theme: Theme
    tickAmount: number
    currency?: string
    xAxisFormatter: (value: number | string) => string
    tooltipFormatter: (value: number | string) => string
}

const getChartOptions = (options: GetChartOptions): ApexOptions => {
    const { theme, tickAmount, currency, xAxisFormatter, tooltipFormatter } = options
    return {
        chart: {
            background: 'transparent',
            stacked: true,
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
            animations: {
                enabled: true,
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
        },
        grid: {
            borderColor: theme.palette.divider,
            strokeDashArray: 2,
        },
        stroke: {
            width: 2,
        },
        theme: {
            mode: 'dark',
        },
        yaxis: {
            labels: {
                formatter: (val) => {
                    return `${val.toLocaleString('cs-CZ')} ${currency ?? ''}`
                },
            },
        },
        xaxis: {
            tickAmount: tickAmount,
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            type: 'numeric',
            tooltip: {
                enabled: false,
            },
            labels: {
                formatter: xAxisFormatter,
            },
        },
        tooltip: {
            x: {
                formatter: tooltipFormatter,
            },
        },
    }
}

interface Props {
    series: ChartSeries[]
    tickAmount: number
    currency?: string
    xAxisFormatter: (value: number | string) => string
    tooltipFormatter: (value: number | string) => string
    title?: string
}

export const MonetizationsChartCard: React.FC<Props> = (props) => {
    const { series, tickAmount, currency, xAxisFormatter, tooltipFormatter, title } = props
    const theme = useTheme()

    const chartOptions = getChartOptions({ theme, tickAmount, currency, xAxisFormatter, tooltipFormatter })

    return (
        <AnalyticCard title={title}>
            <Chart
                height={280}
                options={chartOptions}
                series={series}
                type="bar"
            />
        </AnalyticCard>
    )
}

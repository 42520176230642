import styled from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'

export const StyledDialogContent = styled(DialogContent)`
    width: 560px;
    position: relative;
`

export const CloseIcon = styled(IconButton)`
    position: absolute;
    right: 1rem;
    top: 1rem;
`

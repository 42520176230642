import {
    CustomerId,
    LangCode,
    PLATFORM,
    ScreenConfig,
    TivioConfig,
    VideoSourceCodec,
    VideoSourceEncryption,
    VideoSourceProtocol,
} from '@tivio/types'

import dvtvLogo from '../images/dvtv-logo.png'
import garazLogo from '../images/garaz-logo.png'
import jojLogoGradient from '../images/joj-logo-gradient.png'
import jojLogo from '../images/joj-logo.png'
import mallLogo from '../images/malltv-logo.png'
import oktagonLogo from '../images/oktagon-logo.png'
import stargazeLogo from '../images/stargaze-logo.png'
import starmaxLogo from '../images/starmax-logo.png'

import { JOJ_WEB_LANDING_SCREEN } from './landingScreenConfig'


// eslint-disable-next-line max-len
const investicnipruvodceLogo = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/web-assets%2Flogos%2Fvojta_zizka_logo.svg?alt=media&token=cef79f0a-7f8c-40e9-9041-4a42ae6e4e4f'
const koberskyLogo = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/web-assets%2Flogos%2Fkobersky-logo.svg?alt=media'
const hodinadejepichuLogo = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/web-assets%2Flogos%2Fhodinadejepichu-logo.png?alt=media&token=4cbc561f-ecdb-4268-b112-58c6f3d1dab0'
const cestmirStrakatyLogo = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/web-assets%2Flogos%2Fcestmir_logo.svg?alt=media'
const vicNezSiMysliteLogo = 'https://firebasestorage.googleapis.com/v0/b/tivio-production-input-admin/o/organizations%2FUT9QehLJ1PTEtJtpBZEY%2Forganizations%2FUT9QehLJ1PTEtJtpBZEY%2Flogo%2F%401-1702895670977.png?alt=media'
const kviffLogoWhite = 'https://firebasestorage.googleapis.com/v0/b/tivio-production-input-admin/o/organizations%2FYxs3iHjXmedRJXKyGCvV%2Fkviff-logo-white.png?alt=media'
const sportyTvLogo = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/web-assets%2Flogos%2Fsporty-tv-logo.svg?alt=media'

// TODO TOV-1175 Move somewhere
export interface AccordionItem {
    id: number
    summary: string
    /**
     * Each item is shown as new paragraph
     */
    details: JSX.Element
}

export interface WebLandingBannerConfig {
    /**
     * Banner title
     */
    title: string
    /**
     * Banner subtitle
     */
    subtitle: string
    /**
     * Last line of banner subtitle which is bold.
     */
    subtitleBold?: string
    /**
     * Banner background image
     */
    backgroundImage: string
    /**
     * Banner logo
     */
    logo: string,
    /**
     * Scroll to pricing button handler
     */
    handleScrollToPricing?: () => void
}

export interface WebLandingRowConfig {
    /**
     * Row title
     */
    title: string,
    /**
     * Row subtitle
     */
    subtitle: string,
    /**
     * Row image (not background image, shown on the left/right side of the text)
     */
    image: string,
    /**
     * Where image is shown
     */
    imageSide?: 'left' | 'right'
    /**
     * Row background color
     */
    backgroundColor?: string
}

export interface WebLandingScreenConfig {
    banner: WebLandingBannerConfig
    rows: WebLandingRowConfig[]
    FAQ: AccordionItem[]
}

export enum SubscriptionPriceBadgeDisplayType {
    /**
     * Subscription price badge is always shown on appropriate tvChannel or video regardless of user's purchases.
     * Default state. // TODO TIV-1905 default state for config
     */
    ALWAYS_SHOWN = 'ALWAYS_SHOWN',
    /**
     * Subscription price badge is always hidden on appropriate tvChannel or video regardless of user's purchases.
     *
     * May be useful for customers, who have all videos under subscriptions and it doesn't make sense to show this badge on all items.
     */
    ALWAYS_HIDDEN = 'ALWAYS_HIDDEN',
    /**
     * Subscription price badge is hidden only if that subscription is purchased, otherwise it's shown.
     */
    HIDDEN_IF_PURCHASED = 'HIDDEN_IF_PURCHASED',
}

interface AppConfig {
    // TODO delete this, landingScreen is used instead
    /**
     * If true, app will show landing screen
     */
    allowAnonymous: boolean
    /**
     * If true, app will show registration screen
     */
    allowRegistration?: boolean
    /**
     * If true, app will show bought subscriptions in the user profile
     */
    allowProfileSubscription?: boolean
    /**
     * If true, app will allow to buy subscriptions/videos on mobile apps using Qerko overlay
     */
    allowMobilePurchases?: boolean
    /**
     * If true, app will allow to buy subscriptions/videos on TV apps using Qerko overlay
     */
    allowTVPurchases?: boolean
    /**
     * If true, app will show CMP banner (Consent Management Platform)
     */
    allowCmp?: boolean
    /**
     * If true, app will allow to delete user profile
     */
    allowDeleteProfile?: boolean
    allowSearch?: boolean
    screens?: ScreenConfig[]
    landingScreen?: WebLandingScreenConfig
    socialLogins?: {
        showGoogleLogin?: boolean
        showPatreonLogin?: boolean
        showAppleLogin?: boolean
    },
    /**
     * Defines when subscription price badge should be shown. See {@link SubscriptionPriceBadgeDisplayType} for more info.
     */
    subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType
}

interface AssetsConfig {
    logo: string
    logoGradient?: string
}

interface SearchConfig {
    disableSearchInTags?: boolean
}

interface CustomerConfig {
    tivio: TivioConfig
    apps: { [platform in PLATFORM]?: AppConfig }
    assets: AssetsConfig
    search?: SearchConfig
}

const STARGAZE_CONFIG: CustomerConfig = {
    tivio: {
        secret: 'y3EUDDBzbLxUChdHCb0w',
        deviceCapabilities: [],
        currency: 'CZK',
        language: LangCode.CS,
    },
    apps: {
        [PLATFORM.WEB]: {
            allowAnonymous: true,
            screens: [],
            subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
        },
        [PLATFORM.MOBILE]: {
            allowAnonymous: true,
            allowMobilePurchases: true,
            allowRegistration: true,
            allowCmp: false,
            allowDeleteProfile: false,
            allowProfileSubscription: true,
            subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            screens: [
                { id: 'screen-4TCz-DlJOHymcTLa_hv1w', name: 'DOMŮ', route: 'home' },
            ],
        },
    },
    assets: {
        logo: investicnipruvodceLogo,
    },
    search: {
        disableSearchInTags: true,
    },
}

const CUSTOMER_APPS_CONFIG: { [customer in CustomerId]?: CustomerConfig } = {
    [CustomerId.MALL]: {
        tivio: {
            secret: 'e4EfP1cX6AGtP1WMP8UO', // production, MALL.TV
            verbose: false,
            language: LangCode.EN,
            currency: 'CZK',
            deviceCapabilities: [{
                codec: VideoSourceCodec.H264,
                protocol: VideoSourceProtocol.HLS,
                encryption: VideoSourceEncryption.NONE,
            }],
        },
        apps: {
            [PLATFORM.TV]: {
                allowAnonymous: true,
                screens: [
                    { id: 'screen-SdMRlVYSFqh0u8iuwWMBH', name: 'Home' },
                ],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_SHOWN,
            },
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_SHOWN,
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                screens: [
                    { id: 'screen-feh5CeHZPqwL6WIBTQrqU', name: 'Home' },
                    { id: 'screen-yoTvHtr9p8Df4E0z94FdF', name: 'Kids' },
                ],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_SHOWN,
            },
        },
        assets: {
            logo: mallLogo,
            logoGradient: mallLogo,
        },
    },
    [CustomerId.JOJ]: {
        tivio: {
            secret: 'hWfj4gLtCBqzeZ4pC3hc', // production, TV JOJ
            verbose: false,
            language: LangCode.SK,
            currency: 'EUR',
            deviceCapabilities: [{
                codec: VideoSourceCodec.H264,
                protocol: VideoSourceProtocol.HLS,
                encryption: VideoSourceEncryption.NONE,
            }],
        },
        apps: {
            [PLATFORM.TV]: {
                allowAnonymous: false,
                allowTVPurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                landingScreen: JOJ_WEB_LANDING_SCREEN,
                screens: [
                    // { id: 'screen-Dy26ZOT40B_UySjIRyNOz', name: 'Developer' },
                    {
                        id: 'screen-Lxye9UzYirbdU8gT6zIZ',
                        name: 'Domov',
                        showForUserProfileType: {
                            adults: true,
                            kids: false,
                        },
                    },
                    {
                        id: 'screen-6u1OuNZpEZJ5mvFAr_kwA',
                        name: 'Seriály',
                        showForUserProfileType: {
                            adults: true,
                            kids: false,
                        },
                    },
                    {
                        id: 'screen-sDYvdxFDr6YuXBrJNq4Pf',
                        name: 'Filmy',
                        showForUserProfileType: {
                            adults: true,
                            kids: false,
                        },
                    },
                    {
                        id: 'screen-jNBx-DFZSx6PazsFvlPol',
                        name: 'Šport',
                        showForUserProfileType: {
                            adults: true,
                            kids: false,
                        },
                    },
                    {
                        id: 'screen-szqfXNmL-NXZev2HwvTAz',
                        name: 'Deti',
                        showForUserProfileType: {
                            adults: false,
                            kids: true,
                        },
                    },
                    {
                        id: 'screen-vmptBEawEZaKP5VzokZeE',
                        name: 'Deti',
                        showForUserProfileType: {
                            adults: true,
                            kids: false,
                        },
                    },
                    {
                        id: 'screen-d_xouIReAph2uSRkR70eb',
                        name: 'Dokumenty',
                        showForUserProfileType: {
                            adults: true,
                            kids: false,
                        },
                    },
                ],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,
            },
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                screens: [],
                landingScreen: JOJ_WEB_LANDING_SCREEN,
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: false,
                allowTVPurchases: true,
                allowRegistration: true,
                allowCmp: true,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                screens: [
                    {
                        id: 'screen-Lxye9UzYirbdU8gT6zIZ',
                        name: 'Home',
                        showForUserProfileType: {
                            adults: true,
                            kids: false,
                        },
                    },
                    {
                        id: 'screen-6u1OuNZpEZJ5mvFAr_kwA',
                        name: 'Series',
                        showForUserProfileType: {
                            adults: true,
                            kids: false,
                        },
                    },
                    {
                        id: 'screen-sDYvdxFDr6YuXBrJNq4Pf',
                        name: 'Films',
                        showForUserProfileType: {
                            adults: true,
                            kids: false,
                        },
                    },
                    {
                        id: 'screen-jNBx-DFZSx6PazsFvlPol',
                        name: 'Sport',
                        showForUserProfileType: {
                            adults: true,
                            kids: false,
                        },
                    },
                    {
                        id: 'screen-szqfXNmL-NXZev2HwvTAz',
                        name: 'For kids',
                        showForUserProfileType: {
                            adults: false,
                            kids: true,
                        },
                    },
                    {
                        id: 'screen-vmptBEawEZaKP5VzokZeE',
                        name: 'For kids',
                        showForUserProfileType: {
                            adults: true,
                            kids: false,
                        },
                    },
                    {
                        id: 'screen-pcLk_H0ks15W9AUFZ6Zu9',
                        name: 'Bonusy',
                        showForUserProfileType: {
                            adults: true,
                            kids: false,
                        },
                    },
                ],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,

            },
        },
        assets: {
            logo: jojLogo,
            logoGradient: jojLogoGradient,
        },
    },
    [CustomerId.OKTAGON]: {
        tivio: {
            secret: 'GjvTxnJDrH5CjU7x4JZA', // production, Oktagon
            verbose: false,
            language: LangCode.EN,
            currency: 'EUR',
            deviceCapabilities: [{
                codec: VideoSourceCodec.H264,
                protocol: VideoSourceProtocol.HLS,
                encryption: VideoSourceEncryption.NONE,
            }],
        },
        apps: {
            [PLATFORM.TV]: {
                allowAnonymous: true,
                allowTVPurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                screens: [
                    { id: 'screen-MoJ2LrwtTDDBkPYqKd71K', name: 'Home' },
                    { id: 'screen-UUal-pc3NL1T9fwTiupn9', name: 'Fights' },
                    { id: 'screen-zpkdsQIeAtfc9XK11RU29', name: 'Programs' },
                    /**
                     * Data for this screen in not configured correctly. It was disabled to
                     * pass app submission validation process for LG store
                     */
                    // { id: 'screen-g5GbjEJocw83ERrO4c34E', name: 'Fights' },
                ],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_SHOWN,
            },
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_SHOWN,
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                allowProfileSubscription: false,
                allowCmp: false,
                allowRegistration: false,
                allowDeleteProfile: true,
                allowSearch: true,
                screens: [
                    { id: 'screen-MoJ2LrwtTDDBkPYqKd71K', name: 'Home' },
                    { id: 'screen-UUal-pc3NL1T9fwTiupn9', name: 'Fights' },
                    { id: 'screen-LcFrvC0GgNxEnzHddZoue', name: 'Programs' },
                ],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_SHOWN,
            },
        },
        assets: {
            logo: oktagonLogo,
        },
    },
    [CustomerId.GARAZ]: {
        tivio: {
            secret: 'vprqQV2r2tWmTdoPnbxb',
            deviceCapabilities: [],
            currency: 'EUR',
            language: LangCode.SK,
        },
        apps: {
            [PLATFORM.TV]: {
                allowAnonymous: true,
                allowTVPurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                screens: [
                    { id: 'screen-gfAcwueZcM1RVJVL7S04M', name: 'Home' },
                ],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                allowTVPurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                screens: [
                    { id: 'screen-gfAcwueZcM1RVJVL7S04M', name: 'Home' },
                ],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
        },
        assets: {
            logo: garazLogo,
            logoGradient: garazLogo,
        },
    },
    [CustomerId.INVESTOREES]: {
        tivio: {
            secret: 'INVuXolh1IfD9RK9rEen',
            deviceCapabilities: [],
            currency: 'CZK',
            language: LangCode.CS,
        },
        apps: {
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                // screens are configured in webConfig (@tivio/web)
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: false,
                allowTVPurchases: false,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                screens: [
                    {
                        id: 'screen-3NBJT8wE8BxeR9p99-kcY',
                        name: 'Pořady',
                    },
                    {
                        id: 'screen-zeBV_FpR16eJKWydUYyfM',
                        name: 'Oblíbené',
                    },
                ],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
        },
        assets: {
            logo: '',
        },
    },
    [CustomerId.DVTV]: {
        tivio: {
            secret: 'pdvtvM4PCofoVav0AHGe',
            deviceCapabilities: [],
            currency: 'CZK',
            language: LangCode.CS,
        },
        apps: {
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                // screens are configured in webConfig (@tivio/web)
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
            [PLATFORM.TV]: {
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
                allowAnonymous: true,
                allowTVPurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                screens: [
                    { id: 'screen-KZ8dxhgDT8vwQN6g51cwI', name: 'DOMŮ' },
                    { id: 'screen-AYAuPjeRVBKODE1hDNwCW', name: 'Pořady' },
                ],
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                allowTVPurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                screens: [
                    { id: 'screen-KZ8dxhgDT8vwQN6g51cwI', name: 'DOMŮ' },
                    { id: 'screen-AYAuPjeRVBKODE1hDNwCW', name: 'Pořady' },
                ],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
        },
        assets: {
            logo: dvtvLogo,
        },
        search: {
            disableSearchInTags: true,
        },
    },
    [CustomerId.DVTV_SK]: {
        tivio: {
            secret: 'dvtv_sk_web_HfyL4HbSil',
            deviceCapabilities: [],
            currency: 'EUR',
            language: LangCode.SK,
        },
        apps: {
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                // screens are configured in webConfig (@tivio/web)
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
            [PLATFORM.TV]: {
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
                allowAnonymous: true,
                allowTVPurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                screens: [
                    { id: 'screen-KZ8dxhgDT8vwQN6g51cwI', name: 'DOMŮ' },
                    { id: 'screen-AYAuPjeRVBKODE1hDNwCW', name: 'Pořady' },
                ],
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                allowTVPurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                screens: [
                    { id: 'screen-KZ8dxhgDT8vwQN6g51cwI', name: 'DOMŮ' },
                    { id: 'screen-AYAuPjeRVBKODE1hDNwCW', name: 'Pořady' },
                ],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
        },
        assets: {
            logo: dvtvLogo,
        },
        search: {
            disableSearchInTags: true,
        },
    },
    [CustomerId.INVESTICNI_PRUVODCE]: {
        tivio: {
            secret: 'VZm0B1L59jblQ8M7LbiD',
            deviceCapabilities: [],
            currency: 'CZK',
            language: LangCode.CS,
        },
        apps: {
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                // screens are configured in webConfig (@tivio/web)
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                allowMobilePurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: false,
                socialLogins: {
                    showGoogleLogin: true,
                    showPatreonLogin: true,
                    showAppleLogin: true,
                },
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
                screens: [
                    { id: 'screen-4TCz-DlJOHymcTLa_hv1w', name: 'DOMŮ', route: 'home' },
                    { id: 'screen-g07gqYt4ltpu0N2pRJ_bR', name: 'O PROJEKTU', route: 'about' },
                ],
            },
        },
        assets: {
            logo: investicnipruvodceLogo,
        },
        search: {
            disableSearchInTags: true,
        },
    },
    [CustomerId.ONDREJ_KOBERSKY]: {
        tivio: {
            secret: 'KOBEONeMx2CTj1ZCw0XX',
            deviceCapabilities: [],
            currency: 'CZK',
            language: LangCode.CS,
        },
        apps: {
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                // screens are configured in webConfig (@tivio/web)
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                allowMobilePurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,
                screens: [],
            },
        },
        assets: {
            logo: koberskyLogo,
        },
        search: {
            disableSearchInTags: true,
        },
    },
    [CustomerId.HODINA_DEJEPICHU]: {
        tivio: {
            secret: '8Lz6TTIZYCg2jiiWTFos',
            deviceCapabilities: [],
            currency: 'CZK',
            language: LangCode.CS,
        },
        apps: {
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                // screens are configured in webConfig (@tivio/web)
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                allowMobilePurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,
                screens: [],
            },
        },
        assets: {
            logo: hodinadejepichuLogo,
        },
        search: {
            disableSearchInTags: true,
        },
    },
    [CustomerId.TIVIO_PRO]: {
        tivio: {
            secret: 'ba8APJURKNfPr3Mh6oCm',
            deviceCapabilities: [],
            currency: 'CZK',
            language: LangCode.CS,
        },
        apps: {
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                // screens are configured in webConfig (@tivio/web)
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                allowMobilePurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
                screens: [
                    { id: 'screen-4TCz-DlJOHymcTLa_hv1w', name: 'DOMŮ', route: 'home' },
                ],
            },
        },
        assets: {
            logo: investicnipruvodceLogo,
        },
        search: {
            disableSearchInTags: true,
        },
    },
    [CustomerId.STARGAZE]: STARGAZE_CONFIG,
    [CustomerId.STARMAX]: {
        tivio: {
            secret: 'this-is-not-used',
            deviceCapabilities: [],
            currency: 'CZK',
            language: LangCode.CS,
        },
        apps: {
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
            [PLATFORM.TV]: {
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,
                allowAnonymous: true,
                allowTVPurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                screens: [
                    { id: 'screen-DGceO4IjAjIOSiTdLDJpN', name: 'DOMŮ' },
                    { id: 'screen-qgMdRro0xa08fUnMnGWsG', name: 'PŘEDPLATNÉ' },
                    { id: 'screen-9y2d2ATaBmAZulkGaj7pQ', name: 'PRÉMIOVÉ FILMY' },
                ],
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                allowMobilePurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,
                screens: [
                    { id: 'screen-DGceO4IjAjIOSiTdLDJpN', name: 'DOMŮ', route: 'home' },
                    { id: 'screen-qgMdRro0xa08fUnMnGWsG', name: 'PŘEDPLATNÉ', route: 'subscriptions' },
                    { id: 'screen-9y2d2ATaBmAZulkGaj7pQ', name: 'PRÉMIOVÉ FILMY', route: 'premium' },
                ],
            },
        },
        assets: {
            logo: starmaxLogo,
        },
        search: {
            disableSearchInTags: true,
        },
    },
    [CustomerId.CESTMIR_STRAKATY]: {
        tivio: {
            secret: 'vLwq1C676uDziGTn40HRtRWAaXFveqRm',
            deviceCapabilities: [],
            currency: 'CZK',
            language: LangCode.CS,
        },
        apps: {
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                allowMobilePurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,
            },
        },
        assets: {
            logo: cestmirStrakatyLogo,
        },
        search: {
            disableSearchInTags: true,
        },
    },
    [CustomerId.VIC_NEZ_SI_MYSLITE]: {
        tivio: {
            secret: 'Gx2b9lg3s8AKlUP2tmE5',
            deviceCapabilities: [],
            currency: 'CZK',
            language: LangCode.CS,
        },
        apps: {
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                allowMobilePurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED,
            },
        },
        assets: {
            logo: vicNezSiMysliteLogo,
        },
        search: {
            disableSearchInTags: true,
        },
    },
    [CustomerId.KVIFF_WEB]: {
        tivio: {
            secret: 'web-A5db749mdGaBdlSjhg',
            deviceCapabilities: [],
            currency: 'CZK',
            language: LangCode.CS,
        },
        apps: {
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                // screens are configured in webConfig (@tivio/web)
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
            [PLATFORM.TV]: {
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
                allowAnonymous: true,
                allowTVPurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                screens: [],
            },
            [PLATFORM.MOBILE]: {
                allowAnonymous: true,
                allowTVPurchases: true,
                allowRegistration: true,
                allowCmp: false,
                allowDeleteProfile: false,
                allowProfileSubscription: true,
                allowSearch: true,
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
        },
        assets: {
            logo: kviffLogoWhite,
        },
        search: {
            disableSearchInTags: true,
        },
    },
    [CustomerId.SPORTY_TV]: {
        tivio: {
            secret: '0tA91lLNyZbSu1dbCIlF',
            deviceCapabilities: [],
            currency: 'CZK',
            language: LangCode.CS,
        },
        apps: {
            [PLATFORM.WEB]: {
                allowAnonymous: true,
                // screens are configured in webConfig (@tivio/web)
                screens: [],
                subscriptionPriceBadgeDisplayType: SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN,
            },
        },
        assets: {
            logo: sportyTvLogo,
        },
        search: {
            disableSearchInTags: true,
        },
    },
}


export {
    CUSTOMER_APPS_CONFIG,
}

export type {
    AppConfig, AssetsConfig, CustomerConfig,
    SearchConfig,
}

import { InputHTMLAttributes, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { useAlert } from '../uiHooks/useAlert'


interface Options extends InputHTMLAttributes<HTMLInputElement> {
    onFiles?: (fileList: FileList | null) => void
    errorMessage?: string
}

/**
 * Allows selecting file without file input
 */
const useFileInput = (options: Options) => {
    const { onFiles, errorMessage, multiple, ...inputAttributes } = options
    const inputRef = useRef<HTMLInputElement | null>(null)
    const { showError } = useAlert()
    const [t] = useTranslation()

    useEffect(
        () => {
            const input = document.createElement('input')
            inputRef.current = input

            input.setAttribute('type', 'file')

            Object.entries(inputAttributes).forEach(([key, value]) => {
                input.setAttribute(key, value)
            })

            const listener = (e: Event) => {
                const input = e.target as HTMLInputElement

                if (!input.files) {
                    showError(errorMessage || t('Failed to load file or files.'))
                    return
                }

                if (inputAttributes.accept) {
                    const acceptedTypes = inputAttributes.accept.split(',')
                    const filesLength = (input.files.length && !multiple) ? 1 : input.files.length // only one file is processed if multiple is false

                    for (let i = 0; i < filesLength; i++) {
                        const file = input.files[i]
                        
                        // Check if file type matches any of the accepted types
                        const isAccepted = acceptedTypes.some(acceptType => {
                            // Handle wildcards like 'image/*'
                            if (acceptType.endsWith('/*')) {
                                const prefix = acceptType.split('/*')[0]
                                return file.type && file.type.startsWith(prefix + '/')
                            }
                            // Direct match
                            return acceptType === file.type
                        })

                        if (file.type && !isAccepted) {
                            showError(
                                t('File {{filename}} is not {{acceptType}}', {
                                    filename: file.name,
                                    acceptType: inputAttributes.accept,
                                }),
                            )
                            return // break the loop and exit function because file is unsupported
                        }
                    }
                }

                onFiles?.(input.files)
                input.value = ''
            }

            input.addEventListener('change', listener)

            return () => {
                input.removeEventListener('change', listener)
                input.remove()
            }
        },
        [onFiles, errorMessage, inputAttributes, showError, t, multiple],
    )

    const selectFile = () => {
        inputRef.current?.click()
    }

    return {
        selectFile,
    }
}

export {
    useFileInput,
}

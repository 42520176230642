import { getPurchasableMonetizationWithPromotionPath, PurchasableMonetization, tivio, Voucher } from '@tivio/core-js'
import { useCallback, useEffect, useState } from 'react'

import { useError } from './useError'
import { useUser } from './useUser'


const useVoucher = (voucherId: string) => {
    const { user } = useUser()
    const organizationRef = tivio.organization?.application?.organization?.ref
    const [voucher, setVoucher] = useState<Voucher | null>(null)
    const [isVoucherInitialized, setIsVoucherInitialized] = useState(false)
    const [purchaseId, setPurchaseId] = useState<string | null>(null)
    const [, setActivatedPromotionsIds] = useState<string[]>([])
    const [activatedPromotions, setActivatedPromotions] = useState<{ id: string, isFree?: boolean }[]>([])
    const [activationSuccess, setActivationSuccess] = useState(false)
    const [redirectToSubscriptions, setRedirectToSubscriptions] = useState(false)
    const { error, raiseError, resetError } = useError()
    const [subscriptionsToShow, setSubscriptionsToShow] = useState<PurchasableMonetization[]>([])
    const [videoId, setVideoId] = useState<string | undefined>(undefined)

    useEffect(() => {
        // Initialize Voucher instance asynchronously
        const initVoucher = async () => {
            const v = new Voucher(voucherId, undefined, organizationRef)
            await v.initialize()
            setVoucher(v)
            setIsVoucherInitialized(true)
        }
        if (voucherId) {
            initVoucher()
        }
    }, [voucherId, organizationRef])

    useEffect(() => {
        if (purchaseId && user?.purchases && isVoucherInitialized) {
            const exist = user.purchases.some((purchase) => purchase.id === purchaseId)

            if (exist) {
                voucher?.refresh()
                setActivationSuccess(true)
            }
        }
    }, [purchaseId, user?.purchases, isVoucherInitialized, voucher])

    const activate = useCallback(async () => {
        if (!isVoucherInitialized) {
            return
        }

        resetError()

        try {
            const res = await voucher?.activate() ?? null
            if (typeof res === 'string') {
                setPurchaseId(res)
            } else if (typeof res === 'object' && res?.activatedPromotions) {
                const activatedPromotions = res.activatedPromotions
                setActivatedPromotions(activatedPromotions)

                for (const activatedPromotion of activatedPromotions) {
                    if (!voucher?.organizationId) {
                        continue
                    }
                    try {
                        const promotionPath = `organizations/${voucher.organizationId}/promotions/${activatedPromotion.id}`
                        const monetizationEntity = await getPurchasableMonetizationWithPromotionPath(promotionPath)

                        if (monetizationEntity) {
                            setSubscriptionsToShow((prev) => [...prev, monetizationEntity])
                        }
                    } catch (error) {
                        console.error(error.message)
                    }

                }

                // TODO: It has to be this way now because we don't support multiple transactions to be activated at once
                const videoIdFromPromotion = res?.activatedPromotions.at(0)?.videoId
                setVideoId(videoIdFromPromotion)

                setActivatedPromotionsIds(activatedPromotions.map(promotion => promotion.id))
                if (activatedPromotions.length) {
                    setActivationSuccess(true)
                }
            }
        } catch (e) {
            raiseError(e)
        }
    }, [isVoucherInitialized, resetError, voucher, raiseError])

    useEffect(() => {
        if (activatedPromotions.some(promotion => !promotion.isFree) && !subscriptionsToShow.length) {
            setRedirectToSubscriptions(true)
        }
    }, [activatedPromotions, subscriptionsToShow])

    return {
        activate,
        voucher,
        error,
        activationSuccess,
        isVoucherInitialized,
        redirectToSubscriptions,
        subscriptionsToShow,
        videoId,
    }
}

export { useVoucher }
import { round, uniqueFilter } from '@tivio/common'
import { MONETIZATION_FREQUENCY } from '@tivio/types'
import i18n from 'i18next'

import { getDocumentsByIds } from '../../../firebase/firestore/row'
import { 
    CANCELLED_MONETIZATION_CHART_COLOR_PALETTE,
    EXPIRED_MONETIZATION_CHART_COLOR_PALETTE,
    REST_MONETIZATION_CHART_COLOR_PALETTE,
    VOUCHER_MONETIZATION_CHART_COLOR_PALETTE,
} from '../../../static/constants'
import { getColorPaletteById, getNormalizedChartData } from '../../../utils/chart.utils'
import { percentageWithDecimals } from '../../../utils/math.utils'
import { getTranslation } from '../../../utils/translate.utils'
import { getVideoCoverAsset } from '../../../utils/video.utils'

import type {
    CancellingMonetizationDetail,
    ChartSeriesDataPointDictionary,
    ChurnedMonetizationDetail,
    MonetizationCountDetail,
    MonetizationIncomeDetail,
    MonetizationIncomePPVDetail,
    MonetizationSummaryDetail,
    PayPerViewIncomeDetail,
} from './types'
import type Monetization from '../../../store/Monetization'
import type { ChartIntervals, ChartSeries } from '../../../types/chart'
import type { VideoDocument } from '@tivio/firebase'
import type { CancellingMonetizationsSummaryItem, ChurnedMonetizationsSummaryItem, MonetizationsCountsSummaryItem, MonetizationSummaryItem } from '@tivio/types'



export interface MonetizationAggregationMatrix {
    monetizationSummaryDetails: MonetizationSummaryDetail[]
    generalTotalIncome: number
    subscriptionTotalIncome: number
    payPerViewTotalIncome: number
}

export interface GetMonetizationAggregationMatrixOptions {
    monetizationsAnalytics: MonetizationSummaryItem[]
    monetizations: Monetization[]
    chartIntervals: ChartIntervals
}

export interface GetMonetizationsCountsAggregationMatrixOptions {
    monetizationsCountsAnalytics: MonetizationsCountsSummaryItem[]
    monetizations: Monetization[]
    chartIntervals: ChartIntervals
}

export interface GetCancellingMonetizationsAggregationMatrixOptions {
    cancellingMonetizationsAnalytics: CancellingMonetizationsSummaryItem[]
    monetizations: Monetization[]
    chartIntervals: ChartIntervals
}

export interface GetChurnedMonetizationsAggregationMatrixOptions {
    churnedMonetizationsAnalytics: ChurnedMonetizationsSummaryItem[]
    monetizations: Monetization[]
    chartIntervals: ChartIntervals
}

/**
 * Method take monetizationsAnalytics (raw data from BigQuery database)
 * and aggregate them according to predefined intervals.
 *
 * @param monetizationsAnalytics - An array of view count records from database
 * @param chartIntervals - An array of arrays intervals in unix timestamp ([[ 1683849600, 1683860400 ],...])
 *
 * @returns {MonetizationAggregationMatrix} An object data from aggregation to the next processing
 */
export const getMonetizationAggregationMatrix = ({
    monetizationsAnalytics,
    monetizations,
    chartIntervals,
}: GetMonetizationAggregationMatrixOptions): MonetizationAggregationMatrix => {
    const monetizationAggregationMatrix: MonetizationAggregationMatrix = {
        monetizationSummaryDetails: [],
        generalTotalIncome: 0,
        subscriptionTotalIncome: 0,
        payPerViewTotalIncome: 0,
    }

    let unknownIndex = 1
    for (const [startDateInterval, endDateInterval] of chartIntervals) {
        const measurementDate = startDateInterval * 1000
        const monetizationsAnalyticsForInterval = monetizationsAnalytics.filter(monetizationsSummaryRecord =>
            monetizationsSummaryRecord.date >= startDateInterval &&
            monetizationsSummaryRecord.date < endDateInterval,
        )
        for (const monetizationsSummaryRecord of monetizationsAnalyticsForInterval) {
            const incomeValue = monetizationsSummaryRecord.totalPrice
            const purchasedPrice = monetizationsSummaryRecord.paymentPrice
            const monetizationId = monetizationsSummaryRecord.monetizationId
            const { videoId, regularPaymentCount, newPaymentCount, cancelledPaymentCount, monetizationFrequency } = monetizationsSummaryRecord

            const monetization = monetizations.find(monetization => monetization.getId === monetizationId)
            const isSubscription = monetizationsSummaryRecord.monetizationFrequency !== MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT

            // Quick workaround how to exclude Oktagon PPV on JOJ organization
            if (!monetization && !isSubscription) {
                continue
            }

            const currentMonetizationSummaryDetail = monetizationAggregationMatrix.monetizationSummaryDetails
                .find(monetizationSummaryDetail =>
                    monetizationSummaryDetail.monetizationId === monetizationId &&
                    monetizationSummaryDetail.purchasedPrice === purchasedPrice &&
                    monetizationSummaryDetail.videoId === videoId,
                )


            let monetizationTitle = currentMonetizationSummaryDetail?.title ?? monetization?.getName
            if (!monetizationTitle) {
                monetizationTitle = `${i18n.t('Unknown')} ${unknownIndex}`
                unknownIndex++
            }

            if (isSubscription) {
                monetizationAggregationMatrix.subscriptionTotalIncome += incomeValue
            } else {
                monetizationAggregationMatrix.payPerViewTotalIncome += incomeValue
            }

            monetizationAggregationMatrix.generalTotalIncome += incomeValue

            if (currentMonetizationSummaryDetail) {
                const prevValue = currentMonetizationSummaryDetail.data[measurementDate]?.y ?? 0
                currentMonetizationSummaryDetail.data[measurementDate] = {
                    x: measurementDate,
                    y: prevValue + incomeValue,
                }
                currentMonetizationSummaryDetail.regularPaymentCount += regularPaymentCount
                currentMonetizationSummaryDetail.newPaymentCount += newPaymentCount
                currentMonetizationSummaryDetail.cancelledPaymentCount += cancelledPaymentCount
                currentMonetizationSummaryDetail.totalIncome += incomeValue
            } else {
                const monetizationSummaryDetail: MonetizationSummaryDetail = {
                    monetizationId,
                    purchasedPrice,
                    videoId,
                    monetizationType: isSubscription ? 'subscription' : 'transaction',
                    title: monetizationTitle,
                    regularPaymentCount,
                    newPaymentCount,
                    cancelledPaymentCount,
                    totalIncome: incomeValue,
                    monetizationFrequency,
                    data: {
                        [measurementDate]: {
                            x: measurementDate,
                            y: incomeValue,
                        },
                    },
                }
                monetizationAggregationMatrix.monetizationSummaryDetails.push(monetizationSummaryDetail)
            }
        }
    }
    // Remove nonsense values from DB
    // Unfortunately we have biased data in DB
    monetizationAggregationMatrix.monetizationSummaryDetails = monetizationAggregationMatrix.monetizationSummaryDetails
        .filter(({ purchasedPrice, newPaymentCount, regularPaymentCount, cancelledPaymentCount }) => {
            const hasAllZeroCounts = newPaymentCount === 0 && regularPaymentCount === 0 && cancelledPaymentCount === 0
            return !(purchasedPrice === 0 || hasAllZeroCounts)
        })
    return monetizationAggregationMatrix
}

export const getMonetizationsCountsAggregationMatrix = ({
    monetizationsCountsAnalytics,
    monetizations,
    chartIntervals,
}: GetMonetizationsCountsAggregationMatrixOptions): MonetizationCountDetail[] => {
    const monetizationCountDetails: MonetizationCountDetail[] = []

    let unknownIndex = 1
    for (const [startDateInterval, endDateInterval] of chartIntervals) {
        const measurementDate = startDateInterval * 1000
        const monetizationsAnalyticsForInterval = monetizationsCountsAnalytics.filter(monetizationsSummaryRecord =>
            monetizationsSummaryRecord.date >= startDateInterval &&
            monetizationsSummaryRecord.date < endDateInterval,
        )
        for (const monetizationsSummaryRecord of monetizationsAnalyticsForInterval) {
            const monetizationId = monetizationsSummaryRecord.monetizationId
            const monetization = monetizations.find(monetization => monetization.getId === monetizationId)

            const currentMonetizationSummaryDetail = monetizationCountDetails
                .find(monetizationSummaryDetail =>
                    monetizationSummaryDetail.monetizationId === monetizationId,
                )

            let monetizationTitle = currentMonetizationSummaryDetail?.title ?? monetization?.getName
            if (!monetizationTitle) {
                console.log('unknown', monetizationId)
                monetizationTitle = `${i18n.t('Unknown')} ${unknownIndex}`
                unknownIndex++
            }

            if (currentMonetizationSummaryDetail) {
                const prevValueVoucher = currentMonetizationSummaryDetail.data[measurementDate]?.voucherCount ?? 0
                const prevValueRest = currentMonetizationSummaryDetail.data[measurementDate]?.restCount ?? 0
                currentMonetizationSummaryDetail.data[measurementDate] = {
                    x: measurementDate,
                    voucherCount: prevValueVoucher + (monetizationsSummaryRecord.gateway === 'voucher' ? monetizationsSummaryRecord.count : 0),
                    restCount: prevValueRest + (monetizationsSummaryRecord.gateway !== 'voucher' ? monetizationsSummaryRecord.count : 0),
                }
                currentMonetizationSummaryDetail.restCount += monetizationsSummaryRecord.gateway !== 'voucher' ? monetizationsSummaryRecord.count : 0
                currentMonetizationSummaryDetail.voucherCount += monetizationsSummaryRecord.gateway === 'voucher' ? monetizationsSummaryRecord.count : 0
            } else {
                const monetizationSummaryDetail: MonetizationCountDetail = {
                    monetizationId,
                    monetizationType: monetization?.getType ?? 'transaction',
                    title: monetizationTitle,
                    restCount: monetizationsSummaryRecord.gateway !== 'voucher' ? monetizationsSummaryRecord.count : 0,
                    voucherCount: monetizationsSummaryRecord.gateway === 'voucher' ? monetizationsSummaryRecord.count : 0,
                    monetizationFrequency: monetization?.getFrequency ?? MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT,
                    data: {
                        [measurementDate]: {
                            x: measurementDate,
                            voucherCount: monetizationsSummaryRecord.gateway === 'voucher' ? monetizationsSummaryRecord.count : 0,
                            restCount: monetizationsSummaryRecord.gateway !== 'voucher' ? monetizationsSummaryRecord.count : 0,
                        },
                    },
                }
                monetizationCountDetails.push(monetizationSummaryDetail)
            }
        }
    }
    return monetizationCountDetails
}


export const getCancellingMonetizationsAggregationMatrix = ({
    cancellingMonetizationsAnalytics,
    monetizations,
    chartIntervals,
}: GetCancellingMonetizationsAggregationMatrixOptions): CancellingMonetizationDetail[] => {
    const cancellingMonetizationDetails: CancellingMonetizationDetail[] = []

    for (const [startDateInterval, endDateInterval] of chartIntervals) {
        const measurementDate = startDateInterval * 1000
        const cancellingMonetizationsAnalyticsForInterval = cancellingMonetizationsAnalytics.filter(cancellingMonetizationsSummaryRecord =>
            cancellingMonetizationsSummaryRecord.date >= startDateInterval &&
            cancellingMonetizationsSummaryRecord.date < endDateInterval,
        )
        const monetizationMap = new Map(monetizations.map(m => [m.getId, m]));
        for (const cancellingMonetizationsSummaryRecord of cancellingMonetizationsAnalyticsForInterval) {
            const monetizationId = cancellingMonetizationsSummaryRecord.monetizationId
            const monetization = monetizationMap.get(monetizationId)

            if (!monetization) {
                continue
            }

            const currentMonetizationSummaryDetail = cancellingMonetizationDetails
                .find(cancellingMonetizationDetail =>
                    cancellingMonetizationDetail.monetizationId === monetizationId,
                )


            const monetizationTitle = currentMonetizationSummaryDetail?.title ?? monetization?.getName ?? monetizationId


            if (currentMonetizationSummaryDetail) {
                const prevValue = currentMonetizationSummaryDetail.data[measurementDate]?.y ?? 0
                currentMonetizationSummaryDetail.data[measurementDate] = {
                    x: measurementDate,
                    y: prevValue + cancellingMonetizationsSummaryRecord.cancellingUsers,
                }
                currentMonetizationSummaryDetail.cancellingCount += cancellingMonetizationsSummaryRecord.cancellingUsers
            } else {
                const cancellingMonetizationDetail: CancellingMonetizationDetail = {
                    monetizationId,
                    monetizationType: monetization?.getType ?? 'transaction',
                    title: monetizationTitle,
                    cancellingCount: cancellingMonetizationsSummaryRecord.cancellingUsers,
                    monetizationFrequency: monetization?.getFrequency ?? MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT,
                    data: {
                        [measurementDate]: {
                            x: measurementDate,
                            y: cancellingMonetizationsSummaryRecord.cancellingUsers,
                        },
                    },
                }
                cancellingMonetizationDetails.push(cancellingMonetizationDetail)
            }
        }
    }
    return cancellingMonetizationDetails
}



export const getChurnedMonetizationsAggregationMatrix = ({
    churnedMonetizationsAnalytics,
    monetizations,
    chartIntervals,
}: GetChurnedMonetizationsAggregationMatrixOptions): ChurnedMonetizationDetail[] => {

    const churnedMonetizationDetails: ChurnedMonetizationDetail[] = []

    for (const [startDateInterval, endDateInterval] of chartIntervals) {
        const measurementDate = startDateInterval * 1000

        const churnedRecordsForInterval = churnedMonetizationsAnalytics.filter(
            (record) =>
                record.date >= startDateInterval && record.date < endDateInterval,
        )
        const monetizationMap = new Map(monetizations.map(m => [m.getId, m]));
        for (const record of churnedRecordsForInterval) {
            const { monetizationId, canceledUsers, expiredUsers } = record
            const monetization = monetizationMap.get(monetizationId)
            // If not found we skip this record
            if (!monetization) {
                continue
            }

            const monetizationTitle = monetization?.getName ?? monetizationId

            let existingDetail = churnedMonetizationDetails.find(
                (detail) => detail.monetizationId === monetizationId,
            )

            if (!existingDetail) {
                existingDetail = {
                    monetizationId,
                    monetizationType: monetization?.getType ?? 'transaction',
                    title: monetizationTitle,
                    canceledCount: 0,
                    expiredCount: 0,
                    monetizationFrequency:
                    monetization?.getFrequency ??
                    MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT,
                    data: {},
                }
                churnedMonetizationDetails.push(existingDetail)
            }

            existingDetail.canceledCount += canceledUsers
            existingDetail.expiredCount += expiredUsers

            const dayData = existingDetail.data[measurementDate] || {
                x: measurementDate,
                canceled: 0,
                expired: 0,
            }

            dayData.expired = (dayData.expired ?? 0) + expiredUsers
            dayData.canceled = (dayData.canceled ?? 0) + canceledUsers

            existingDetail.data[measurementDate] = dayData
        }
    }

    return churnedMonetizationDetails
}

export interface GetMonetizationSummaryListOptions {
    monetizationSummaryDetails: MonetizationSummaryDetail[]
    generalTotalIncome: number
}

export const getMonetizationSummaryList = ({
    monetizationSummaryDetails,
    generalTotalIncome,
}: GetMonetizationSummaryListOptions): MonetizationIncomeDetail[] => {

    const monetizationSummaryList: MonetizationIncomeDetail[] = monetizationSummaryDetails.map((monetizationSummaryDetail) => {
        const totalIncomeForMonetization = Object.values(monetizationSummaryDetail.data).reduce((sum, val) => sum + val.y, 0)
        const percentage = percentageWithDecimals(totalIncomeForMonetization, generalTotalIncome, 2) ?? 0
        const purchaseCount = monetizationSummaryDetail.newPaymentCount + monetizationSummaryDetail.regularPaymentCount
        return {
            monetizationId: monetizationSummaryDetail.monetizationId,
            title: monetizationSummaryDetail.title,
            percentage,
            monetizationType: monetizationSummaryDetail.monetizationType,
            monetizationFrequency: monetizationSummaryDetail.monetizationFrequency,
            purchasedPrice: monetizationSummaryDetail.purchasedPrice,
            purchaseCount,
            totalIncome: round(monetizationSummaryDetail.totalIncome, 2),
        }
    }).sort((a, b) => b.totalIncome - a.totalIncome)
    return monetizationSummaryList
}

export const getGroupedDataTotalIncome = (monetizationSummaryDetails: MonetizationSummaryDetail[]): MonetizationSummaryDetail[] => {
    const aggregatedMonetizationSummaryDetails = monetizationSummaryDetails
        .reduce((resultArray: MonetizationSummaryDetail[], monetizationSummaryDetail) => {
            const isSubscription = monetizationSummaryDetail.monetizationType !== 'transaction'
            const monetizationId = isSubscription ? monetizationSummaryDetail.monetizationId : 'ppv'
            const monetizationTitle = isSubscription ? monetizationSummaryDetail.title : 'PPV'
            const prevMonetizationSummaryDetail = resultArray
                .find(existingMonetizationSummaryDetail => existingMonetizationSummaryDetail.monetizationId === monetizationId)

            if (prevMonetizationSummaryDetail) {
                // prevMonetizationSummaryDetail.data
                const updatedDataPointDictionary = Object.keys(monetizationSummaryDetail.data)
                    .reduce((data: ChartSeriesDataPointDictionary, measurementDateString) => {
                        const measurementDate = parseInt(measurementDateString)
                        const prevValue = prevMonetizationSummaryDetail.data[measurementDate]?.y ?? 0
                        const incomeValue = monetizationSummaryDetail.data[measurementDate].y
                        data[measurementDate] = {
                            x: measurementDate,
                            y: prevValue + incomeValue,
                        }
                        return data
                    }, {})
                prevMonetizationSummaryDetail.totalIncome += monetizationSummaryDetail.totalIncome
                prevMonetizationSummaryDetail.regularPaymentCount += monetizationSummaryDetail.regularPaymentCount
                prevMonetizationSummaryDetail.newPaymentCount += monetizationSummaryDetail.newPaymentCount
                prevMonetizationSummaryDetail.cancelledPaymentCount += monetizationSummaryDetail.cancelledPaymentCount
                prevMonetizationSummaryDetail.data = { ...prevMonetizationSummaryDetail.data, ...updatedDataPointDictionary }
            } else {
                const newMonetizationSummaryDetail: MonetizationSummaryDetail = {
                    ...monetizationSummaryDetail,
                    monetizationId,
                    title: monetizationTitle,
                }
                resultArray.push(newMonetizationSummaryDetail)
            }
            return resultArray
        }, [])
    return aggregatedMonetizationSummaryDetails
}

export const getGroupedDataPPVIncome = async (ppvMonetizationSummaries: MonetizationSummaryDetail[]): Promise<PayPerViewIncomeDetail[]> => {
    const videoDocuments = await getVideosForMonetization(ppvMonetizationSummaries)
    const aggregatedPPVSummaryDetails: PayPerViewIncomeDetail[] = []

    const processPPVMonetizations = (ppvMonetizations: MonetizationSummaryDetail[], videoId?: string, videoData?: VideoDocument, coverImage?: string) => {
        const monetizationSummaries: MonetizationIncomePPVDetail[] = []
        const chartData: ChartSeriesDataPointDictionary = {}

        for (const ppvMonetizationSummary of ppvMonetizations) {
            const purchaseCount = ppvMonetizationSummary.newPaymentCount + ppvMonetizationSummary.regularPaymentCount
            // Try find existing record according unique pair
            const prevMonetizationSummary = monetizationSummaries
                .find(({ monetizationId, purchasedPrice }) =>
                    monetizationId === ppvMonetizationSummary.monetizationId &&
                    purchasedPrice === ppvMonetizationSummary.purchasedPrice,
                )

            // Update old value or create new
            if (prevMonetizationSummary) {
                prevMonetizationSummary.purchaseCount += purchaseCount
                prevMonetizationSummary.totalIncome += round(ppvMonetizationSummary.totalIncome, 2)
            } else {
                const monetizationSummary = {
                    monetizationId: ppvMonetizationSummary.monetizationId,
                    title: ppvMonetizationSummary.title,
                    purchasedPrice: ppvMonetizationSummary.purchasedPrice,
                    purchaseCount,
                    totalIncome: round(ppvMonetizationSummary.totalIncome, 2),
                } as MonetizationIncomePPVDetail
                monetizationSummaries.push(monetizationSummary)
            }

            for (const measurementDateString of Object.keys(ppvMonetizationSummary.data)) {
                const measurementDate = parseInt(measurementDateString)
                const prevValue = chartData[measurementDate]?.y ?? 0
                const incomeValue = ppvMonetizationSummary.data[measurementDate].y
                chartData[measurementDate] = {
                    x: measurementDate,
                    y: prevValue + incomeValue,
                }

            }
        }

        const ppvMonetizationSummary: PayPerViewIncomeDetail = {
            id: videoId ?? ppvMonetizations[0].monetizationId,
            videoId,
            name: videoData?.name ?? ppvMonetizations[0].title,
            videoDescription: videoData?.description ?? '',
            coverImage,
            monetizationSummaries,
            totalIncome: monetizationSummaries.reduce((sum, current) => sum += current.totalIncome, 0),
            chartData,
        }
        aggregatedPPVSummaryDetails.push(ppvMonetizationSummary)
    }

    for (const videoSnapshot of videoDocuments) {
        const videoData = videoSnapshot.data() as VideoDocument
        const videoId = videoSnapshot.id
        const coverImage = getVideoCoverAsset(videoData.assets ?? {})
        // I ask for specific videos according data from ppvMonetizationSummaries, I know that I have it
        const ppvMonetizationsForVideo = ppvMonetizationSummaries.filter(ppvSummaryDetail => ppvSummaryDetail.videoId === videoId)

        processPPVMonetizations(ppvMonetizationsForVideo, videoId, videoData, coverImage)
    }

    const monetizationsWithoutVideo = ppvMonetizationSummaries.filter(ppvSummaryDetail => !ppvSummaryDetail.videoId)
    for (const ppvMonetizationSummary of monetizationsWithoutVideo) {
        processPPVMonetizations([ppvMonetizationSummary])
    }

    return aggregatedPPVSummaryDetails.sort((a, b) => b.totalIncome - a.totalIncome)
}

export interface GetSeriesDataOptions<T> {
    dataDetails: T[]
    chartIntervals: ChartIntervals
    colorPalette?: { [key: string]: string }
    getSeriesName?: (dataDetail: T) => string
    getColorKey?: (index: number, dataDetail: T) => string
}

export const getSeriesData = <T extends MonetizationSummaryDetail | CancellingMonetizationDetail>({
    dataDetails,
    chartIntervals,
    colorPalette,
    getSeriesName,
    getColorKey,
}: GetSeriesDataOptions<T>) => {
    const innerColorPalette = colorPalette ??
        getColorPaletteById({
            data: dataDetails.sort((a, b) => b.monetizationId.localeCompare(a.monetizationId)),
            getKey: (index, dataDetail) => dataDetail.monetizationId ?? index.toString(),
        })

    const seriesData: ChartSeries[] = dataDetails
        .map((monetizationSeries, index) => {
            const colorKey = getColorKey ? getColorKey(index, monetizationSeries) : monetizationSeries.monetizationId
            const series: ChartSeries = {
                id: monetizationSeries.monetizationId,
                name: getSeriesName?.(monetizationSeries) ?? monetizationSeries.title,
                color: innerColorPalette[colorKey],
                data: getNormalizedChartData(chartIntervals, monetizationSeries.data),
            }
            return series
        })
    return seriesData
}

export const getSeriesPPVData = ({
    dataDetails,
    chartIntervals,
    colorPalette,
    getSeriesName,
}: GetSeriesDataOptions<PayPerViewIncomeDetail>) => {
    const innerColorPalette = colorPalette ?? getColorPaletteById({
        data: dataDetails.sort((a, b) => b.id.localeCompare(a.id)),
        getKey: (index, dataDetail) => dataDetail.videoId ?? index.toString(),
    })

    const seriesData: ChartSeries[] = dataDetails
        .map(videoSeries => {
            return {
                id: videoSeries.id,
                name: getSeriesName?.(videoSeries) ?? getTranslation(videoSeries.name ?? ''),
                color: innerColorPalette[videoSeries.id],
                data: getNormalizedChartData(chartIntervals, videoSeries.chartData),
            }
        })
    return seriesData
}

export const getVideosForMonetization = async (monetizationSummaryDetails: MonetizationSummaryDetail[]) => {
    const videoIds = monetizationSummaryDetails.flatMap(({ videoId }) => videoId ? [videoId] : []).filter(uniqueFilter)
    const videoResult = await getDocumentsByIds('videos', videoIds)
    return videoResult.flatMap(videoSnapshots => videoSnapshots.docs)
}




export const getChurnedChartSeries = (
    churnedMonetizationsAggregation: ChurnedMonetizationDetail[],
    chartIntervals: ChartIntervals,
): ChartSeries[] => {
    const allSeries: ChartSeries[] = []

    churnedMonetizationsAggregation.forEach((detail, index) => {
        // We'll create 2 series for each subscription: one for expired, one for canceled
        const expiredSeries: ChartSeries = {
            id: `${detail.monetizationId}-expired`,
            name: `${detail.title} (Expired)`,
            group: 'expired', // So that all "expired" stack together
            data: [],
            color: EXPIRED_MONETIZATION_CHART_COLOR_PALETTE[index % EXPIRED_MONETIZATION_CHART_COLOR_PALETTE.length],
        }
    
        const canceledSeries: ChartSeries = {
            id: `${detail.monetizationId}-canceled`,
            name: `${detail.title} (Canceled)`,
            group: 'canceled', // So that all "canceled" stack together
            data: [],
            color: CANCELLED_MONETIZATION_CHART_COLOR_PALETTE[index % CANCELLED_MONETIZATION_CHART_COLOR_PALETTE.length],
        }
    
        chartIntervals.forEach(([startDateInterval]) => {
            const measurementDate = startDateInterval * 1000
            const dayData = detail.data[measurementDate]
            if (dayData) {
                expiredSeries.data.push({
                    x: dayData.x,
                    y: dayData.expired ?? 0,
                })
                canceledSeries.data.push({
                    x: dayData.x,
                    y: dayData.canceled ?? 0,
                })
            }
        })
    
        allSeries.push(expiredSeries, canceledSeries)
    })

    return allSeries
}


export const getMonetizationCountsChartSeries = (
    monetizationCountsAggregation: MonetizationCountDetail[],
    chartIntervals: ChartIntervals,
): ChartSeries[] => {
    const allSeries: ChartSeries[] = []

    monetizationCountsAggregation.forEach((detail, index) => {
        const voucherSeries: ChartSeries = {
            id: `${detail.monetizationId}-voucher`,
            name: `${detail.title} (Voucher)`,
            group: 'voucher', // So that all "voucher" stack together
            data: [],
            color: VOUCHER_MONETIZATION_CHART_COLOR_PALETTE[index % VOUCHER_MONETIZATION_CHART_COLOR_PALETTE.length],
        }
    
        const restSeries: ChartSeries = {
            id: `${detail.monetizationId}`,
            name: `${detail.title}`,
            group: 'rest', // So that all "rest" stack together
            data: [],
            color: REST_MONETIZATION_CHART_COLOR_PALETTE[index % REST_MONETIZATION_CHART_COLOR_PALETTE.length],
        }
    
        chartIntervals.forEach(([startDateInterval]) => {
            const measurementDate = startDateInterval * 1000
            const dayData = detail.data[measurementDate]
            if (dayData) {
                voucherSeries.data.push({
                    x: dayData.x,
                    y: dayData.voucherCount ?? 0,
                })
                restSeries.data.push({
                    x: dayData.x,
                    y: dayData.restCount ?? 0,
                })
            }
        })
    
        allSeries.push(voucherSeries, restSeries)
    })

    return allSeries
}
import { createLogger } from '@tivio/common'
import { tivio } from '@tivio/core-js'
import { TvChannelType } from '@tivio/types'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

import { useError } from './useError'

import type { TvProgram, Video } from '@tivio/types'


const logger = createLogger('useCurrentProgram')

/**
 * Get current live program by tv channel id
 * @param tvChannelId - tv channel id
 * @param tryBulkQuery - {@see tivio.getCurrentProgram}
 */
export const useCurrentProgram = (tvChannelId: string, tryBulkQuery = false) => {
    const [video, setVideo] = useState<Video | null>(null)
    const [virtualProgram, setVirtualProgram] = useState<TvProgram | null>(null)
    const [tvProgram, setTvProgram] = useState<TvProgram | null>(null)
    const { error, resetError, raiseError } = useError()

    useEffect(() => {
        (async () => {
            resetError()

            try {
                const tvChannel = await tivio.getTvChannelById(tvChannelId)
                if (tvChannel.type === TvChannelType.VIRTUAL) {
                    const epg = await tivio.getEpgTvProgramsByTvChannel({
                        from: dayjs(),
                        to: dayjs().add(4, 'hours'),
                        tvChannelId: tvChannel.id,
                    })
                    if (epg && epg.length > 0) {
                        const [currentProgram] = epg
                        setVirtualProgram(currentProgram ?? null)
                        setVideo(currentProgram?.video ?? null)
                    }
                } else {
                    const { video, tvProgram } = await tivio.getCurrentProgram(tvChannelId, tryBulkQuery)
                    setTvProgram(tvProgram)
                    setVideo(video)
                }
            } catch (e) {
                raiseError(e)
                logger.error(e)
            }
        })()
    }, [raiseError, resetError, tryBulkQuery, tvChannelId])

    const videoStart = video?.from || virtualProgram?.from || tvProgram?.from
    const videoEnd = video?.to || virtualProgram?.to || tvProgram?.to

    const progress = useMemo(() => {
        const now = new Date().getTime()

        if (videoStart && videoEnd) {
            const duration = videoEnd.getTime() - videoStart.getTime()
            const progress = now - videoStart.getTime()

            return (progress / duration) * 100
        }

        return 0
    }, [video])

    const time = useMemo(() => {

        if (videoStart && videoEnd) {
            return `${dayjs(videoStart.getTime()).format('HH:mm')} - ${dayjs(videoEnd.getTime()).format('HH:mm')}`
        }

        return ''
    }, [video])


    return {
        video,
        error,
        progress,
        time,
    }
}

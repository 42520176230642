import { useCallback, useState } from 'react'

import { uploadFile } from '../../../firebase/storage'


/**
 * Upload hook
 * Serves for uploading files to storage
 */
const useUpload = () => {
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ progress, setProgress ] = useState(0)

    const upload = useCallback(async (file: File, filePath: string): Promise<string> => {
        setLoading(true)

        try {
            return await uploadFile(file, filePath, { onProgress: setProgress })
        }
        finally {
            setLoading(false)
        }
    }, [])

    return {
        loading,
        progress,
        upload,
    }
}

export default useUpload

import { Box, Dialog, IconButton } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import Close from '@material-ui/icons/Close'
import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'

import { Tag } from '../../store/Tag'
import { ContentInterface } from '../../types/content'
import { AppThemeProvider } from '../AppThemeProvider'
import { TagsAutocomplete } from '../screen/TagsAutocomplete'
import { StyledDialogContent, CloseIcon } from '../common/StyledDialogComponents'


interface ContentTagsDialogProps {
    isOpen: boolean
    content: ContentInterface
    onClose?: () => void
    onTagChange?: (tags: Tag[]) => void
}

export const ContentTagsDialog: React.FC<ContentTagsDialogProps> = observer(({ isOpen, onClose, content }) => {
    return (
        <AppThemeProvider type="light">
            <Dialog open={isOpen}>
                <StyledDialogContent>
                    <CloseIcon onClick={onClose}>
                        <Close />
                    </CloseIcon>
                    <Box
                        pt={6}
                        pb={4}
                    >
                        <TagsAutocomplete
                            selectedTags={content.tags.map((tag) => tag.getRef)}
                            onTagChange={content.updateTags}
                        />
                    </Box>
                </StyledDialogContent>
            </Dialog>
        </AppThemeProvider>
    )
})

import { Box, Button, Dialog, TextField, Typography } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'
import { nanoid } from 'nanoid'

import { SurveyItem } from '@tivio/firebase'
import { ContentInterface } from '../../types/content'
import { AppThemeProvider } from '../AppThemeProvider'
import { Header } from '../common'
import { CloseIcon, StyledDialogContent } from '../common/StyledDialogComponents'
import { useFileInput } from '../hooks/dataHooks/useFileInput'
import useUpload from '../hooks/uiHooks/useUpload'
import { Article } from '../../store/Article'
import { getFileExtension } from '../../utils/file.utils'

interface ContentSurveyDialogProps {
    isOpen: boolean
    content: Article
    surveyItem?: SurveyItem
    onClose?: () => void
    onSurveyItemChange?: (surveyItem: SurveyItem[]) => void
}

const ImagePreview = styled.div<{ imageUrl: string }>`
    width: 200px;
    height: 200px;
    background-image: url(${props => props.imageUrl || '/apps/admin/public/media/200x200-placeholder.svg'});
    background-size: cover;
    background-position: center;
    margin: 1rem auto;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

export const ContentSurveyDialog: React.FC<ContentSurveyDialogProps> = observer(({ isOpen, onClose, content, surveyItem, onSurveyItemChange }) => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [error, setError] = useState<string | null>(null)
    const [t] = useTranslation()
    const [imageFile, setImageFile] = useState<File | null>(null)
    const [uploadId, setUploadId] = useState<string>('')

    const { loading, progress, upload } = useUpload()

    useEffect(() => {
        if (surveyItem) {
            setTitle(surveyItem.title)
            setDescription(surveyItem.description)
            setImageUrl(surveyItem.imageUrl)
            setUploadId(surveyItem.id || nanoid())
        } else {
            setTitle('')
            setDescription('')
            setImageUrl('')
            setUploadId(nanoid())
        }
        setImageFile(null)
    }, [surveyItem, isOpen])

    const getImageUploadPath = (file: File): string => {
        const extension = getFileExtension(file.name) || 'jpg'
        return `${content.getDocumentPath}/survey-metadata/${uploadId}.${extension}`
    }

    const { selectFile } = useFileInput({
        onFiles: async (files) => {
            if (files && files[0]) {
                const file = files[0]
                setImageFile(file)

                try {
                    const objectUrl = URL.createObjectURL(file)
                    setImageUrl(objectUrl)

                    const uploadPath = getImageUploadPath(file)
                    const downloadUrl = await upload(file, uploadPath)

                    setImageUrl(downloadUrl)

                    URL.revokeObjectURL(objectUrl)
                } catch (err) {
                    console.error('Failed to upload image', err)
                    setError(t('Failed to upload image.'))
                }
            }
        },
        accept: 'image/*',
        errorMessage: t('Failed to load image.'),
    })

    const handleSave = async () => {
        if (!title || !description) {
            setError(t('Title and description are required.'))
            return
        }

        if (!imageUrl) {
            setError(t('Please upload an image.'))
            return
        }

        setError(null)

        // Create survey item with the Firebase Storage URL
        const newSurveyItem: SurveyItem = {
            id: uploadId,
            title,
            description,
            imageUrl,
        }

        const updatedSurveyItems = surveyItem
            ? content.surveyItems.map(item => item.id === surveyItem.id ? newSurveyItem : item)
            : [...content.surveyItems, newSurveyItem]

        try {
            await content.updateSurveyItems(updatedSurveyItems)

            if (onSurveyItemChange) {
                onSurveyItemChange(updatedSurveyItems)
            }

            if (onClose) {
                onClose()
            }

            // Reset form
            setTitle('')
            setDescription('')
            setImageUrl('')
            setImageFile(null)
            setUploadId(nanoid())
        } catch (err) {
            console.error('Failed to save survey item', err)
            setError(t('Failed to save survey item.'))
        }
    }

    return (
        <AppThemeProvider type="light">
            <Dialog open={isOpen} onClose={() => { setError(null); setImageUrl(''); onClose && onClose() }}>
                <StyledDialogContent>
                    <CloseIcon onClick={() => { setError(null); setImageUrl(''); onClose && onClose() }}>
                        <Close />
                    </CloseIcon>
                    <Header
                        title={t('Survey Item')}
                    />
                    <Box pb={4}>
                        <TextField
                            label="Title"
                            fullWidth
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Description"
                            fullWidth
                            multiline
                            minRows={3}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            margin="normal"
                            required
                        />
                        <ImagePreview imageUrl={imageUrl} />
                        {loading && (
                            <Box mt={1} textAlign="center">
                                <Typography variant="body2">
                                    {t('Uploading...')} {Math.round(progress)}%
                                </Typography>
                            </Box>
                        )}
                        <Button
                            variant="outlined"
                            onClick={selectFile}
                            style={{ display: 'block', margin: '0 auto' }}
                            disabled={loading}
                        >
                            {t('Select Image')}
                        </Button>
                        <Box mt={2}>
                            {error && <Typography color="error">{error}</Typography>}
                            <Button variant="contained" color="primary" onClick={handleSave} disabled={loading}>
                                {t('Save')}
                            </Button>
                        </Box>
                    </Box>
                </StyledDialogContent>
            </Dialog>
        </AppThemeProvider>
    )
})

import { DocumentReference, OrganizationDocument, TagDocument, VideoDocument } from '@tivio/firebase'
import { LangCode, VideoTranscodingStatus, VideoType } from '@tivio/types'
import i18n from 'i18next'

import { createGlobalVideo } from '../../creator/video.creator'
import store from '../../store'
import Organization from '../../store/Organization'
import Video from '../../store/Video'
import { asyncSome, notEmptyFilter } from '../../utils/array.utils'
import { getFileExtension } from '../../utils/file.utils'
import { getTranslation } from '../../utils/translate.utils'
import { firebaseTimestampFromDate, getFirestore, loggerFirestore, VIDEOS_INPUT_BUCKET_URI } from '../app'
import { RefsPagination } from '../pagination'
import { uploadFile } from '../storage'

import { videoConverter } from './converters'

import type firebase from 'firebase/app'


const getVideosCollection = () => {
    return getFirestore().collection('videos').withConverter(videoConverter)
}

export const updateVideo = async (video: Video, updateObject: { cover: string }) => {
    try {
        await getVideosCollection().doc(video.id).update(updateObject)

        loggerFirestore.info(`Video ${video.id} ${video.getName} updated with`, updateObject)
    } catch (e) {
        loggerFirestore.error(`Failed to update video ${video.id}. Error:`, e)
        throw new Error(e)
    }
}

/**
 * Uploads a video file to storage and updates video data with that file.
 *
 * @param file File from HTML Form.
 * @param video Video store object.
 */
export const uploadVideoFile = async (file: File, video: Video, onProgress?: (progress: number) => void) => {
    const fileExtension = getFileExtension(file.name)

    try {
        const uploadedFileUrl = await uploadFile(file, `${video.organization.id}/${video.id}.${fileExtension}`, {
            metadata: { customMetadata: { name: file.name } },
            onProgress: onProgress ?? video.setProgress,
            bucketUri: VIDEOS_INPUT_BUCKET_URI,
        })

        await video.update({
            inputFileUrl: `${VIDEOS_INPUT_BUCKET_URI}/${video.organization.id}/${video.id}.${fileExtension}`,
            profiles: {},
            ...(video.isUploaded ? { reuploadTranscodingStatus: VideoTranscodingStatus.ON_HOLD } : { transcodingStatus: VideoTranscodingStatus.ON_HOLD }),
        })
    } catch (e) {
        loggerFirestore.error('Failed to upload video', e)
        throw new Error(e)
    }
}

/**
 * Uploads a subtitles file to storage and updates video data with that file.
 *
 * @param file File from HTML Form.
 * @param language Language code.
 * @param video Video store object.
 */
export const uploadSubtitlesFile = async (file: File, language: LangCode, video: Video) => {
    try {
        const uploadedFileUrl = await uploadFile(file, `${video.organization.id}/${video.id}-${language}.srt`, {
            metadata: { customMetadata: { name: file.name } },
            bucketUri: VIDEOS_INPUT_BUCKET_URI,
        })

        const subtitles = (() => {
            // Replace existing subtitles
            if (video.subtitles.find((s) => s.language === language && s.draft)) {
                return video.subtitles.map((s) => (s.language === language ? { ...s, createdAt: firebaseTimestampFromDate(new Date()) } : s))
            } else {
                // Create new subtitles
                return video.subtitles.concat({
                    url: uploadedFileUrl,
                    language,
                    createdAt: firebaseTimestampFromDate(new Date()),
                    draft: true,
                })
            }
        })()

        await video.setSubtitles(subtitles)
    } catch (e) {
        loggerFirestore.error('Failed to upload subtitles', e)
        throw new Error(e)
    }
}

/**
 * Gets video from DB if the video belongs to given organization.
 *
 * @param videoId Video's document ID.
 * @param organizationRef Organization reference to which video belongs.
 * @returns Video data and reference. Undefined if video doesn't exist.
 */
export const getOrganizationVideoById = async (videoId: string, organizationRef: firebase.firestore.DocumentReference<OrganizationDocument>) => {
    const videosSnapshot = await getVideosCollection().doc(videoId).get()

    const videoData = videosSnapshot.data()

    if (
        !videosSnapshot.exists ||
        (videoData?.organizationRef.id !== organizationRef.id && !videoData?.organizationAccessRefs?.some((ref) => ref.id === organizationRef.id))
    ) {
        return
    }

    return {
        data: videoData,
        ref: videosSnapshot.ref,
    }
}

const getGlobalVideosRefs = async (organization: Organization) => {
    const videosSnapshot = await getVideosCollection().where('organizationRef', '==', organization.ref).orderBy('created', 'desc').get()

    return videosSnapshot.docs.map((document) => document.ref)
}

const createGlobalVideosPagination = async (organization: Organization) => {
    const videosRefs = await getGlobalVideosRefs(organization)

    organization.globalVideosPagination = new RefsPagination<VideoDocument, Video>(
        videosRefs,
        (ref, data) => {
            return createGlobalVideo(ref, data, organization)
        },
        true,
        30,
    )
}

const addVideo = async (data: VideoDocument) => {
    const videoRef = await getVideosCollection().add(data)

    loggerFirestore.info(`Video with id ${videoRef.id} created`)

    return videoRef
}

const getVideosIdsByLangUrlName = async (organizationRef: DocumentReference, langCode: LangCode, urlName: string) => {
    const videosSnapshot = await getVideosCollection()
        .where('organizationRef', '==', organizationRef)
        .where(`urlName.${langCode}`, 'array-contains', urlName)
        .get()

    return videosSnapshot.docs.map((document) => document.id)
}

const getVideosByName = async (organizationRef: DocumentReference, name: string, langCode?: LangCode) => {
    // startsWith filter, copied from https://stackoverflow.com/a/57290806/19853762
    const end = name?.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1))
    const fieldName = langCode ? `name.${langCode}` : 'name'

    let query = await getVideosCollection().where('organizationRef', '==', organizationRef)

    if (name) {
        query = query.where(fieldName, '>=', name).where(fieldName, '<', end)
    }

    const snapshot = await query.limit(50).get()

    const allVideos = await Promise.all(
        snapshot.docs.map((videoSnapshot) => {
            const videoData = videoSnapshot.data()

            if (videoData) {
                return { name: getTranslation(videoData.name), ref: videoSnapshot.ref }
            }
        }),
    )

    return allVideos.filter(notEmptyFilter)
}

const getVideoByAllowedRebalancingVideoRef = async (organizationRef: DocumentReference, videoRef: DocumentReference) => {
    const snapshot = await getVideosCollection()
        .where('organizationRef', '==', organizationRef)
        .where('allowedRebalancingVideoRefs', 'array-contains', videoRef)
        .get()

    const allVideos = await Promise.all(
        snapshot.docs.map((videoSnapshot) => {
            const videoData = videoSnapshot.data()

            if (videoData) {
                return { name: getTranslation(videoData.name), ref: videoSnapshot.ref, sources: videoData.sources }
            }
        }),
    )

    return allVideos.filter(notEmptyFilter)
}

const hasDistributionTag = (linkedVideo: Video) => linkedVideo.tags.some(({ tagId }) =>
    store.globalDistributionTags.some(({ tagId: distributionTagId }) => tagId === distributionTagId)
)

const checkLinkedVideo = async (linkedVideo: Video | null) => {
    if (linkedVideo?.getType === VideoType.VIRTUAL_PROGRAM) {
        await linkedVideo.initTags()
        return hasDistributionTag(linkedVideo)
    }
    return false
}

export const checkCanDeleteVideo = async (video: Video, showAlert?: boolean) => {
    if (video.tags.some(({ tagId }) => store.globalDistributionTags.some(({ tagId: distributionTagId }) => tagId === distributionTagId))) {
        if (showAlert) {
            store.getAlert.showErrorAlert(i18n.t('Can\'t delete a video with distribution enabled.'))
        }
        return false
    }

    await video.loadLinkedVideos()

    if (video.linkedVideos) {
        const hasDistributionOldWay = await asyncSome(video.linkedVideos, async ({ video: linkedVideo }) => await checkLinkedVideo(linkedVideo))

        if (hasDistributionOldWay) {
            if (showAlert) {
                store.getAlert.showErrorAlert(i18n.t('Can\'t delete a video with distribution enabled.'))
            }
            return false
        }
    }


    return true
}

export const getSeriesEpisodeVideoDoc = async (
    organization: Organization,
    tagRef: DocumentReference<TagDocument>,
    seasonNumber: number,
    episodeNumber: number,
) => {
    const query = getVideosCollection()
        .where('tags', 'array-contains', tagRef)
        .where('seasonNumber', '==', seasonNumber)
        .where('episodeNumber', '==', episodeNumber)
    const snapshot = await query.get()

    if (snapshot.empty) {
        return
    }

    return new Video(snapshot.docs[0].ref, snapshot.docs[0].data(), organization)
}

export const getVideoRef = (videoId: string) => {
    return getVideosCollection().doc(videoId)
}

export {
    getGlobalVideosRefs,
    getVideosCollection,
    getVideosIdsByLangUrlName,
    getVideoByAllowedRebalancingVideoRef,
    createGlobalVideosPagination,
    getVideosByName,
    addVideo,
}

import { MonetizationPlacementType } from '@tivio/firebase'
import { VideoTranscodingStatus, VideoType } from '@tivio/types'
import i18n from 'i18next'
import { makeAutoObservable } from 'mobx'

import { createSectionPlaylist, createSectionVideo } from '../creator/video.creator'
import { VIDEOS_INPUT_BUCKET_URI } from '../firebase/app'
import { addPlaylist, addVideo, deleteElement } from '../firebase/firestore/element'
import { uploadFile } from '../firebase/storage'
import Logger from '../logger'
import { alertError, alertSuccess } from '../utils/alert.utils'
import { getFileExtension } from '../utils/file.utils'
import { goSectionPage } from '../utils/history.utils'

import { getTranslation } from '../utils/translate.utils'

import type { ElementInterface } from '../components/section/SectionElementInterface'
import type { VideoInterface } from '../components/section/SectionVideoEditor'
import type Channel from './Channel'
import type Video from './Video'
import type { VideoUploaderInterface } from './VideoUploader'
import type { MonetizationField, SectionDocument, VideoDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


const logger = new Logger('Section')

class Section implements VideoUploaderInterface {
    private readonly ref: firebase.firestore.DocumentReference<SectionDocument>
    private firestoreData: SectionDocument
    private readonly channel: Channel
    private elements: (Video)[] = []

    constructor(
        ref: firebase.firestore.DocumentReference<SectionDocument>,
        firestoreData: SectionDocument,
        channel: Channel,
    ) {
        this.ref = ref
        this.firestoreData = firestoreData
        this.channel = channel

        makeAutoObservable(this)
    }

    uploadVideo = async (videoInterface: VideoInterface, inPlaylist?: boolean) => {
        try {
            const videoData: VideoDocument = {
                name: videoInterface.name,
                defaultName: getTranslation(videoInterface.name, this.getChannel.getOrganization.languages),
                hide: Boolean(inPlaylist),
                organizationRef: this.getChannel.getOrganization.ref,
                description: videoInterface.description,
                duration: videoInterface.duration,
                created: videoInterface.created,
                publishedStatus: videoInterface.publishedStatus,
                type: VideoType.VIDEO,
                transcodingStatus: VideoTranscodingStatus.ON_HOLD,
                channelRef: this.getChannel.getRef,
                sectionRef: this.getRef,
                tags: [],
            }

            // TODO this should be done via a cloudfn, and not only for
            // channels but for sections too
            const monetizationsObjs = this.channel.getMonetizationsObjs
            if (monetizationsObjs.length) {
                videoData.monetizations = monetizationsObjs
                    .map(
                        (mon: MonetizationField) => ({ ...mon, placementType: MonetizationPlacementType.CHANNEL }),
                    ) as any as MonetizationField[]
            }

            const videoRef = await addVideo(videoData) as firebase.firestore.DocumentReference<VideoDocument>
            const video = createSectionVideo(videoRef, videoData, this, this.getChannel.getOrganization)

            this.getChannel.getOrganization.globalVideosPagination.addItemToStart(video, videoRef)

            videoInterface.onVideo && videoInterface.onVideo(video)

            if (videoInterface.videoFile) {
                const file = videoInterface.videoFile
                const fileExtension = getFileExtension(file.name)

                await uploadFile(
                    file,
                    video.getUploadPath(fileExtension, video),
                    {
                        onProgress: progress => {
                            videoInterface.onProgress && videoInterface.onProgress(videoRef.id, progress)
                        },
                        metadata: { customMetadata: { 'name': file.name } },
                        bucketUri: VIDEOS_INPUT_BUCKET_URI,
                    },
                )
            }

            return video
        }
        catch (e) {
            logger.error(e)
            throw new Error(e)
        }
    }

    uploadVideos = async (videoInterfaces: VideoInterface[], inPlaylist?: boolean) => {
        try {
            const videos = await Promise.all(
                videoInterfaces.map((video) => this.uploadVideo(video, inPlaylist)),
            )

            if (!inPlaylist) {
                await this.updateElements(this.getElements.concat(videos))
            }

            return videos
        }
        catch (e) {
            logger.error(e)
            throw new Error(e)
        }
    }

    uploadPlaylist = async (playlistInterface: ElementInterface, videoInterfaces: VideoInterface[]) => {
        try {
            const videos = await this.uploadVideos(videoInterfaces, true)

            const playlistData: VideoDocument = {
                name: playlistInterface.name,
                defaultName: getTranslation(playlistInterface.name, this.getChannel.getOrganization.languages),
                created: playlistInterface.created,
                hide: playlistInterface.hide,
                organizationRef: this.getChannel.getOrganization.ref,
                description: playlistInterface.description,
                sectionRef: this.ref,
                type: VideoType.PLAYLIST,
                channelRef: this.getChannel.getRef,
                publishedStatus: playlistInterface.publishedStatus,
                videos: videos.map(video => video.getRef as firebase.firestore.DocumentReference<VideoDocument>),
            }
            const playlistRef = await addPlaylist(playlistData)
            const playlist = createSectionPlaylist(
                playlistRef as firebase.firestore.DocumentReference<VideoDocument>,
                playlistData,
                this,
                this.getChannel.getOrganization,
            )

            await this.updateElements(this.getElements.concat(playlist))

            return playlist
        }
        catch (e) {
            logger.error(e)
            throw new Error(e)
        }
    }

    async updateSectionName(name: string) {
        const temp = this.firestoreData.name

        try {
            this.firestoreData.name = name
            await this.ref.update({ name })
            alertSuccess(i18n.t('Section name updated'))
        }
        catch (e) {
            this.firestoreData.name = temp
            alertError(i18n.t('Failed to update section name'))
            logger.error(e)
        }
    }

    async updateElements(elements: (Video)[]) {
        const tempElements = this.getElements

        try {
            this.setElements = elements

            await this.ref.update({ elements: this.getElements.map(element => element.getRef) })
        }
        catch (e) {
            logger.error(e)
            this.setElements = tempElements
            throw new Error(e)
        }
    }

    handleDeleteElement = async (element: Video) => {
        if (element.getType === VideoType.PLAYLIST) {
            await Promise.all((element as Video).getVideos.map(async video => await deleteElement(video)))
        }

        await deleteElement(element)
    }

    async deleteElement(elementToDelete: Video) {
        try {
            await this.handleDeleteElement(elementToDelete)
            await this.updateElements(this.getElements.filter(element => elementToDelete.getId !== element.getId))

            const message = elementToDelete.getType === VideoType.VIDEO
                ? i18n.t('Video deleted')
                : i18n.t('Playlist deleted')

            alertSuccess(message)
        }
        catch (e) {
            alertError(i18n.t('Failed to delete'))
            logger.error(e)
        }
    }

    goSectionPage = () => {
        goSectionPage(this)
    }

    get getOrganizationRef() {
        return this.channel.getOrganization.ref
    }

    get getElements() {
        return this.elements
    }

    get getElementsRefs() {
        return this.firestoreData.elements
    }

    get getId() {
        return this.ref.id
    }

    get getName() {
        return this.firestoreData.name
    }

    get getChannel() {
        return this.channel
    }

    get getRef() {
        return this.ref
    }

    get getVideos() {
        return this.elements.reduce<Video[]>((acc, element: Video) => {
            switch (element.getType) {
                case VideoType.VIDEO:
                    acc = acc.concat(element as Video)
                    break
                case VideoType.PLAYLIST:
                    acc = acc.concat((<Video>element).getVideos)
            }
            return acc
        }, [])
    }

    set setElements(elements: (Video)[]) {
        this.elements = elements
    }

}

export default Section

import { Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { ArticleDocument } from '@tivio/firebase'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next/hooks'

import { addArticle, createArticle, createArticleData } from '../../../firebase/firestore/content'
import { goArticlePage } from '../../../utils/history.utils'
import { ArticlesList } from '../../articles'
import { DefaultPageContainer } from '../../DefaultPageContainer'
import { useOrganization } from '../../hooks'



export const ArticlesPage = observer(() => {
    const [t] = useTranslation()

    const { organization } = useOrganization()

    const handleAddArticle = async () => {
        if (!organization) {
            return
        }

        const articleData: ArticleDocument = createArticleData(organization)
        const articleRef = await addArticle(articleData)
        const article = createArticle(organization, articleRef, articleData)
        organization.addArticle(article)
        goArticlePage(article.id)
    }

    return (
        <DefaultPageContainer
            title={t('Articles')}
            buttons={[
                <Button
                    key="button-add-article"
                    startIcon={<Add />}
                    component="span"
                    size="small"
                    onClick={handleAddArticle}
                >
                    {t('Add article')}
                </Button>,
            ]
            }
        >
            <ArticlesList />
        </DefaultPageContainer>
    )
})

import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { getTranslation } from '../../utils/translate.utils'
import { useOrganization } from '../hooks'
import { useArticles } from '../hooks/dataHooks/useArticles'
import useBottomScrollListener from '../hooks/uiHooks/useBottomScrollListener'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'
import { VideoListSkeleton } from '../video/VideoListSkeleton'

import { ArticleItem } from './components'

import { ArticleDocument } from '@tivio/firebase'
import { firebaseTimestampFromDate } from '../../firebase/app'
import { addArticle, createArticle } from '../../firebase/firestore/content'
import type { Article } from '../../store/Article'


export const ArticlesList = observer(() => {
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const { confirmAction } = useConfirmAction()
    const { isLoading, articles, fetchMore } = useArticles()

    const handleDeleteClick = (e: React.MouseEvent, article: Article) => {
        e.preventDefault()
        e.stopPropagation()

        confirmAction(() => {
            organization?.deleteArticle(article)
        }, `${t('Are you sure you want to delete article ')} ${getTranslation(article.name, organization?.languages)}?`)
    }

    const handleDuplicateClick = async (e: React.MouseEvent, article: Article) => {
        e.preventDefault()
        e.stopPropagation()

        if (!organization) {
            return
        }

        const articleData = article.getData()
        const newArticleName = Object.keys(articleData.name).reduce((acc, lang: string) => {
            acc[lang] = `${(articleData.name as Record<string, string>)[lang]} (Copy)`
            return acc
        }, {} as Record<string, string>)

        const newArticleData: ArticleDocument = {
            ...articleData,
            name: newArticleName,
            created: firebaseTimestampFromDate(),
        }
        const newArticleRef = await addArticle(newArticleData)
        const newArticle = createArticle(organization, newArticleRef, newArticleData)
        organization.addArticle(newArticle)
    }

    const handleBottomScroll = () => {
        if (fetchMore && !isLoading) {
            fetchMore()
        }
    }
    useBottomScrollListener(handleBottomScroll, { offset: 500, triggerOnNoScroll: true })

    // Initial loading of articles.
    // TODO: Make also loading for pagination
    if (isLoading && articles.length === 0) {
        return <VideoListSkeleton />
    }

    return (
        <>
            {articles.map((article) => (
                <ArticleItem
                    key={article.id}
                    article={article}
                    languages={organization?.languages}
                    rightComp={
                        <>
                            <IconButton
                                size="small"
                                onClick={(e) => handleDuplicateClick(e, article)}
                                title={t('Duplicate article')}
                            >
                                <FileCopyIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={(e) => handleDeleteClick(e, article)}
                                title={t('Delete article')}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </>
                    }
                />
            ))}
        </>
    )
})

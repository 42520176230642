import { formatDuration } from '@tivio/common'
import { ProcessStatus, VideoProcessingStatus } from '@tivio/firebase'
import { VideoType } from '@tivio/types'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { PREVIOUS_SCROLL_POSITION_KEY } from '../../../static/constants'
import { PAGES } from '../../../static/enum'
import { getVideoProcesses, listenToEncodingStatus } from '../../hooks/dataHooks/useEncodingProcesses'
import { useEpgUI } from '../../hooks/dataHooks/useEpgUI'

import { ScheduleItem } from './ScheduleItem'

import type { TvProgram } from '../../../store/TvProgram'
import type {
    DocumentReference,
    EncodingProcessPayload,
    ProcessDocument,
    TvChannelDocument,
    VideoDocument,
    VideoProfile,
} from '@tivio/firebase'
import type { Dayjs } from 'dayjs'


const TV_PROGRAM_TIME_FORMAT = 'HH:mm'

type Props = {
    item: TvProgram
    columnDate: Dayjs
    onDelete: (item: TvProgram) => void
    onClick?: (item: TvProgram) => void
    isLocked?: boolean
    tvChannel: TvChannelDocument | null
    videoQualities: number[]
}

/**
 * Represents one TV Program as item in EPG Schedule.
 * Goes to video detail page on item click.
 */
export const EpgScheduleItem = ({ item, columnDate, onDelete, isLocked, tvChannel, videoQualities }: Props) => {
    const { computeHeightForItem } = useEpgUI()
    const { push } = useHistory()
    const [processStatus, setProcessStatus] = useState<ProcessStatus | null>(null)

    const title = item.name
    const titleShort = (item.shortName ?? item.name)?.substring(0, 40)
    const description = item.video?.getDescription ?? ''
    const image = item.image ?? ''

    const heightPx = computeHeightForItem(item, columnDate)

    const itemOverflow = (() => {
        if (item.overflownFrom(columnDate)) {
            return 'end'
        } else if (item.overflownTo(columnDate)) {
            return 'start'
        }
        return undefined
    })()

    const upperTitle = (() => {
        // if item is over midnight, then add date of another day
        const overflowFormat = `(DD MMM) ${TV_PROGRAM_TIME_FORMAT}`

        const fromFormat = itemOverflow === 'start' ? overflowFormat : TV_PROGRAM_TIME_FORMAT
        const toFormat = itemOverflow === 'end' ? overflowFormat : TV_PROGRAM_TIME_FORMAT

        const duration = formatDuration(item.getDuration('minute', { ignoreSmallerUnits: true }), 'minute')

        return `${item.from.format(fromFormat)} - ${item.to.format(toFormat)} (${duration})`
    })()

    const onItemDelete = (event?: React.MouseEvent) => {
        // prevent firing onItemClick
        event?.preventDefault()
        event?.stopPropagation()

        onDelete(item)
    }

    const onItemClick = () => {
        const videoId = item.video?.id
        if (videoId) {
            sessionStorage.setItem(PREVIOUS_SCROLL_POSITION_KEY, `${window.scrollY}`)
            push(`${PAGES.VIDEOS}/${videoId}`)
        }
    }

    const getFailedProcesses = useCallback((
        videoProcesses: ProcessDocument<EncodingProcessPayload>[],
        videoProfileQualities: number[],
    ) => {
        // Find qualities that are missing in videoProfileQualities from videoQualities of current channel
        if (videoQualities.length === 0 || videoProfileQualities.length === 0) {
            return false
        }
        const missingQualities = videoQualities.filter(quality => !videoProfileQualities.includes(+quality))
        for (const process of videoProcesses) {
            const processQuality = process.payload.videoTrack
            // Check if process quality is in missingQualities and process is not waiting or in progress. Means that the process is FAILED or ABORTED
            // Return boolean to start listening to the rest process
            if (processQuality && missingQualities.includes(+processQuality) && (process.status === ProcessStatus.FAILED || process.status === ProcessStatus.ABORTED)) {
                return true
            }
        }
        return false
    }, [videoQualities])



    const getDoneProcesses = useCallback((
        videoProfileQualities: number[],
    ) => {
        // Check if all videoQualities from current channel are in videoProfileQualities
        if (!videoQualities.length || !videoProfileQualities.length) {
            return false
        }
        return videoQualities.every(quality => videoProfileQualities.includes(+quality))
    }, [videoQualities])



    useEffect(() => {
        const video = item.video
        let disposer = () => {}
        if (tvChannel && video) {
            const fetchProcessStatus = async () => {
                let videoRef: DocumentReference<VideoDocument> | undefined = video.ref
                if (video.data.type === VideoType.VIRTUAL_PROGRAM) {
                    videoRef = video.originalVideoRef 
                }
                
                if (tvChannel.profileRefs && tvChannel.profileRefs.at(0) && videoRef) {
                    const tvChannelProfileId = tvChannel.profileRefs.at(0)?.id as string
                    const videoDoc = await videoRef.get()
                    const videoData = videoDoc.data() as VideoDocument
                    const videoProfile = videoData.profiles?.[tvChannelProfileId] as VideoProfile | undefined
                    const videoProfileQualities: number[] = (videoProfile?.videoQualities ?? []).map(quality => +quality)
                    const done = getDoneProcesses(videoProfileQualities)
                    if (done) {
                        setProcessStatus(ProcessStatus.DONE)
                        return
                    }
                    const videoProcesses = await getVideoProcesses(videoRef, tvChannel.profileRefs.at(0)!)
                    const failed = getFailedProcesses(videoProcesses, videoProfileQualities)
                    if (failed) {
                        setProcessStatus(ProcessStatus.FAILED)
                        return
                    }
                    if (videoProcesses.length > 0) {
                        disposer = listenToEncodingStatus(videoRef, tvChannel.profileRefs.at(0)!, setProcessStatus)
                    }
                }
            }
            fetchProcessStatus()
        }

        return () => {
            disposer()
        }
    }, [tvChannel, item, getDoneProcesses, getFailedProcesses, videoQualities])

    return (
        <ScheduleItem
            title={title}
            titleShort={titleShort}
            upperTitle={upperTitle}
            description={description}
            image={image}
            heightPx={heightPx}
            overflow={itemOverflow}
            onDelete={isLocked ? undefined : onItemDelete}
            onClick={onItemClick}
            processStatus={processStatus ?? undefined}
        />
    )
}

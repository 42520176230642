export const GB = 1024 ** 3

export const UNIX_HOUR = 60 * 60

export const ON_EACH_CHAPTER = 'on-each-chapter'


export const VIEWS_CHART_COLOR_PALETTE = [
    '#07456b',
    '#0190c8',
    '#44d7b6',
    '#5b808d',
    '#01b7ff',
    '#87bd9d',
    '#c28452',
    '#6a6ebe',
]

export const MONETIZATION_CHART_COLOR_PALETTE = [
    '#019eff',
    '#006086',
    '#505cff',
    '#00ad88',
    '#71ffc4',
    '#ffa06a',
    '#d46000',
    '#be6abe',
]

export const VOUCHER_MONETIZATION_CHART_COLOR_PALETTE = [
    '#019eff',
    '#007be7',
    '#0057cd',
    '#008cf4',
    '#0069db',
    '#0045be',
    '#0033af',
    '#001e9e',
]


export const REST_MONETIZATION_CHART_COLOR_PALETTE = [
    '#71ffc4',
    '#52cb89',
    '#349851',
    '#61e5a6',
    '#43b16c',
    '#258037',
    '#15691d',
    '#005200',
]



export const CANCELLING_MONETIZATION_CHART_COLOR_PALETTE = [
    '#ffef14',
    '#c6b60d',
    '#8e8007',
    '#403700',
    '#736605',
    '#a99a0a',
    '#594e03',
    '#e2d210',
]

export const CANCELLED_MONETIZATION_CHART_COLOR_PALETTE = [
    '#f50057',
    '#d6024a',
    '#b8033d',
    '#9b0431',
    '#7f0326',
    '#64031b',
    '#4a0211',
    '#330000',
]

export const EXPIRED_MONETIZATION_CHART_COLOR_PALETTE = [
    '#c7209a',
    '#dd13b8',
    '#af297f',
    '#f003da',
    '#ff00ff',
    '#962f68',
    '#7c3254',
    '#613344',
]

/**
 * Key for the previous scroll position in local storage
 */
export const PREVIOUS_SCROLL_POSITION_KEY = 'previous-scroll-position'

import { DocumentReference, TaskDocument, TaskPayloadMap, TaskType, Timestamp, TaskQueue } from '@tivio/firebase'
import { FirebaseDocumentData, FirestoreDocumentSnapshot, getFirestore } from '../app'
import { Task } from '../../store/Task'


export type ScheduleTaskData<T extends TaskType> = {
    type: T
    payload: TaskPayloadMap[T]
    scheduledTime: Timestamp
    queue: TaskQueue
}

export const getTasksCollection = <T extends TaskType>() => getFirestore()
    .collection('tasks')
    .withConverter({
        fromFirestore: (snapshot: FirestoreDocumentSnapshot): TaskDocument<T> => {
            return snapshot.data() as TaskDocument<T>
        },
        toFirestore: (taskFirestore: TaskDocument<T>): FirebaseDocumentData => {
            return taskFirestore
        },
    })


export const subscribeToTasksByContentRef = <T extends TaskType>(
    contentRef: DocumentReference, 
    callback: (tasks: Task[]) => void
): () => void => {
    return getFirestore()
    .collection('tasks')
    .where('contentRef', '==', contentRef)
    .onSnapshot(snapshot => {
        const tasks = snapshot.docs.map((doc) => new Task(doc.ref as DocumentReference<TaskDocument<T>>, doc.data() as TaskDocument<T>))
        callback(tasks)
    })
}